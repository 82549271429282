import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../../contexts/NewPackageContext";
import { ProcoreToolGroupings } from "../../../types/submittal";
import { createLazyQueryHook } from "../../GraphqlHelpers";

export type ExtractAutomationToolFilterData = {
  extractAutomationToolFilters: ProcoreToolFilter[];
  extractAutomationToolGroupings: ProcoreToolGroupings[];
};

export type ExtractAutomationToolFilterVariables = {
  accountId: string;
  procoreToolEngineName: string;
};

export const getExtractAutomationToolFiltersQuery = gql`
query GetExtractAutomationToolFilters($accountId: ID!, $procoreToolEngineName: ID!) {
  extractAutomationToolFilters(accountId: $accountId, procoreToolEngineName: $procoreToolEngineName) {
    id
    label
    type
    multiple
    dataType
    values {
      id
      key
      value
    }
    value {
      startDate
      endDate
    }
    key
  }

  extractAutomationToolGroupings(accountId: $accountId, procoreToolEngineName: $procoreToolEngineName) {
    id
    label
    attribute
    attributeSource
    defaultValue
    groupingLabel
    operator
    conditional
  }
}
`;

export const useGetExtractAutomationToolFilters = createLazyQueryHook<ExtractAutomationToolFilterData, ExtractAutomationToolFilterVariables>(getExtractAutomationToolFiltersQuery);