import { ProcoreToolFilter } from "../contexts/NewPackageContext";
import { BaseProcoreType } from "../types";

export type SelectFilterValue = {
  id: string;
  key?: number;
  value: string;
}

export type DateRangeFilterValue = {
  startDate?: Date,
  endDate?: Date
}

export function isSelectFilter(filter: ProcoreToolFilter): boolean {
  return filter.type === 'select';
}

export type ProcoreToolGroupings = {
  id: string;
  label: string;
  attribute: string;
  attributeSource: string;
  defaultValue: string;
  groupingLabel: string;
  operator: string;
  conditional: boolean;
}

export type Submittal = BaseProcoreType & {
  title: string;
  submittalType?: string;
  status?: string;
  distributed: boolean;
  specSection?: string;
  responsibleContractor?: string;
  locationName?: string;
  formattedNumber?: string;
  submittalPackageTitle?: string;
  specDivisionNumber?: string;
  specDivision?: string;
}

export type SubmittalResponse = {
  id: number;
  name: string;
  considered: string;
};

export type AttachmentOption = 'all' | 'distributed' | 'last_workflow_response'
export type RequirementDescriptionImportOptions = 'description' | 'title';


export type SubmittalExtractOptions = {
  attachment_option: AttachmentOption;
  single_pdf: boolean;
  workflow_responses: SubmittalResponse[];
}
