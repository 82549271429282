import React from "react";
import * as procoreIframeHelpers from "@procore/procore-iframe-helpers";
import { AuthContext } from "../contexts/AuthContext";
import { Box, Typography } from "@mui/material";
import { Colors } from "../styles";
import ProcoreIconWhite from "./shared/ProcoreIconWhite";
import { Button } from "./shared/Button";

interface LoginProps {
  procoreAuthUrl: string;
}

const Login = ({ procoreAuthUrl }: LoginProps): JSX.Element => {
  const authState = React.useContext(AuthContext);

  function authenticate() {
    const context = procoreIframeHelpers.initialize();

    context.authentication.authenticate({
      url: procoreAuthUrl,
      onSuccess: function (_payload) {
        authState.setAuthenticated(true);
      },
      onFailure: function (error) {
        console.log(error);
      },
    });
  }

  return (
    <Box display="flex" height={1} width={1} justifyContent="center" alignItems="start" style={{
      background: 'url("/images/login-background.svg") no-repeat center'
    }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
        width={"362px"}
        style={{
          gap: '28px',
          top: '25%',
          background: 'white',
          borderRadius: '8px',
          padding: '40px 48px 40px 48px',
          boxShadow: '0px 1px 10px rgba(26, 32, 36, 0.06), 0px 4px 5px rgba(26, 32, 36, 0.07), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)'
        }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBottom="2px"
          style={{ gap: "4px" }}
        >
          <img src="/images/extracts-pro-logo-banner.svg" alt="Extracts Pro Logo Banner" />
          <Typography
            style={{
              fontSize: 14,
              color: Colors.darkishGray,
              fontWeight: 400,
            }}
          >
            Extract your project data with ease
          </Typography>
        </Box>
        <Button
          onClick={authenticate}
          size="large"
          variant="contained"
          fullWidth={true}
          style={{ height: "48px" }}
          startIcon={
            <ProcoreIconWhite style={{ marginRight: "4px", height: "16px" }} />
          }
        >
          Login with Procore
        </Button>
        <Typography
          style={{
            fontSize: 11,
            color: Colors.darkishGray,
            textAlign: "center",
            lineHeight: "16px",
          }}
        >
          By logging in with Procore, you agree to the{" "}
          <a
            href="https://trailb.tech/terms-of-use"
            style={{ color: Colors.newAccentBlue }}
            target="_blank" rel="noreferrer"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://trailb.tech/privacy-policy"
            style={{ color: Colors.newAccentBlue }}
            target="_blank" rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
