import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { FilterOptionInput } from "../../types";
import { ProcoreInspection } from "../../types/inspection";
import { ProcoreToolGroupings } from "../../types/submittal";

export type ProcoreInspectionFilterData = {
  inspectionFilters: ProcoreToolFilter[];
  inspectionDefaultFilters: ProcoreToolFilter[];
  inspectionGroupings: ProcoreToolGroupings[];
};

export type ProcoreFilterInspectionData = {
  inspections: ProcoreInspection[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreInspectionVariables = {
  accountId: string;
  procoreProjectServerId: number;
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export type ProcoreFilterInspectionVariables = ProcoreInspectionVariables & {
  filters: FilterOptionInput[]
};

export const InspectionFragment = gql`
fragment InspectionFragment on ProcoreInspection {
  id
  procoreServerId
  formattedTitle
  responsibleContractor
  inspectionType
  trade
  status
  specificationSection
  listTemplateName
  locationName
  locationTier1
  formattedNumber
  description
}
`;

export const filterProcoreInspectionsQuery = gql`
query FilterProcoreInspections($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  inspections(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...InspectionFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${InspectionFragment}
`;

export const fetchPaginatedInspectionsQuery = gql`
query FilterPaginatedProcoreInspections($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  inspections(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...InspectionFragment
  }
}
${InspectionFragment}
`;