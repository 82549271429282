import { gql } from "@apollo/client";
import { CloseoutLogDataGridView, CloseoutLogExportOption, CloseoutLogVendorUrl, LogCustomField, LogFileRequest, LogRequirement, LogRequirementItem, LogRequirementTrade, LogRequirementType, ProcoreTool, ResponsibleContractor, SpecSection } from "../../../types";
import { createQueryHook } from "../../GraphqlHelpers";
import { ProcoreToolGroupings, SubmittalResponse } from "../../../types/submittal";

export type CloseoutLogData = {
  responsibleContractors: ResponsibleContractor[];
  logRequirementTypes: LogRequirementType[];
  logCustomFields: LogCustomField[];
  logRequirementTrades: LogRequirementTrade[];
  specificationSections: SpecSection[];
  logRequirements: LogRequirement[];
  logFileRequests: LogFileRequest[];
  logRequirementItems: LogRequirementItem[];
  procoreTools: ProcoreTool[];
  closeoutLogGroupings: ProcoreToolGroupings[];
  currentCloseoutLogDataGridView: CloseoutLogDataGridView;
  currentDuplicateLogRequirementsDataGridView: CloseoutLogDataGridView;
  currentCreateCloseoutLogFileRequestsDataGridView: CloseoutLogDataGridView;
  currentCloseoutLogFileRequestGridView: CloseoutLogDataGridView;
  closeoutLogExportOption: CloseoutLogExportOption;
  closeoutLogVendorUrls: CloseoutLogVendorUrl[];
  submittalResponses: SubmittalResponse[];
};

export type CloseoutLogDataVariables = {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
};

export const ResponsibleContractorFragment = gql`
  fragment ResponsibleContractorFragment on ResponsibleContractor {
    id
    name
    procoreServerId
    businessPhone
    emailAddress
    city
    countryCode
    stateCode
    zip
    active
    fax
    address
  }
`;

export const GetCloseoutLogDataQuery = gql`
  query GetCloseoutLogData(
    $accountId: ID!
    $procoreProjectServerId: ID!
    $closeoutLogId: ID!
  ) {
    responsibleContractors(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      ...ResponsibleContractorFragment
    }

    submittalResponses(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId) {
      id
      name
      considered
    }

    closeoutLogVendorUrls(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      id
      name
      url
    }

    logRequirementTypes(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      id
      name
    }

    logCustomFields(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      id
      name
      fieldType
      key
      values {
        id
        value
      }
    }

    logRequirementTrades(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      id
      name
    }

    closeoutLogGroupings(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId,
      closeoutLogId: $closeoutLogId
    ) {
      id
      label
      attribute
      attributeSource
      defaultValue
      groupingLabel
      operator
      conditional
    }

    specificationSections(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      id
      name
      divisionName
      procoreServerId
    }

    logRequirements(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      id
      description
      status
      logRequirementType {
        id
        name
      }
      logRequirementTrade {
        id
        name
      }
      customFields {
        id
        key
        fieldType
        value {
          id
          value
        }
      }
      specSection {
        id
        name
        divisionName
        procoreServerId
      }
      responsibleContractor {
        id
        name
        procoreServerId
      }
    }

    logRequirementItems(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      id
      recordType
      recordServerId
      engineName
      logRequirementId
      payload
    }

    logFileRequests(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      id
      title
      logRequirementId
      description
      internalNotes
      dueDate
      complete
      status
      translatedStatus
      assignees {
        id
        logFileRequestId
        procoreServerId
        procoreUserName
        procoreUserEmail
      }
      closeoutManager {
        id
        procoreUserName
      }
      responsibleContractor {
        id
        name
        procoreServerId
      }
      vendorWorkflowRequest {
        id
        logFileRequestId
        status
        translatedStatus
        comment
        vendorWorkflowResponse {
          id
          logFileRequestId
          status
          comment
          translatedStatus
          attachments {
            id
            procoreFileServerId
            filename
            vendorWorkflowResponseId
            procoreCreatedAt
            procoreRecordUrl
          }
        }
      }
      logRequirementTrade {
        id
        name
      }
      specSection {
        id
        name
        divisionName
        procoreServerId
      }
      logRequirementDescription
    }

    procoreTools(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId) {
      id
      title
      engineName
      procoreServerId
    }

    currentCloseoutLogDataGridView(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId) {
      id
      name
      personal
      columnDefinitions
      sortModel
      groupingColumnDefinition
      groupings
      filters
      markRequirementCompleteOnFileUpload
      markRequirementCompleteOnApprovedFileRequest
    }

    currentDuplicateLogRequirementsDataGridView(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId) {
      id
      name
      personal
      columnDefinitions
      sortModel
      groupingColumnDefinition
      groupings
      filters
      markRequirementCompleteOnFileUpload
      markRequirementCompleteOnApprovedFileRequest
    }

    currentCreateCloseoutLogFileRequestsDataGridView(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId) {
      id
      name
      personal
      columnDefinitions
      sortModel
      groupingColumnDefinition
      groupings
      filters
      markRequirementCompleteOnFileUpload
      markRequirementCompleteOnApprovedFileRequest
    }

    currentCloseoutLogFileRequestGridView(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId) {
      id
      name
      personal
      columnDefinitions
      sortModel
      groupingColumnDefinition
      groupings
      filters
      markRequirementCompleteOnFileUpload
      markRequirementCompleteOnApprovedFileRequest
    }

    closeoutLogExportOption(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId) {
      id
      includeTableOfContents
      includeVendorList
      groupings
      importSubmittalDescriptionFrom
      toolExtractOptions
    }
  }
  ${ResponsibleContractorFragment}
`;

export const useGetCloseoutLogData = createQueryHook<
  CloseoutLogData,
  CloseoutLogDataVariables
>(GetCloseoutLogDataQuery);
