import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { FilterOptionInput } from "../../types";
import { ProcoreBid } from "../../types/bid";
import { ProcoreToolGroupings } from "../../types/submittal";

export type ProcoreBidFilterData = {
  bidFilters: ProcoreToolFilter[];
  bidDefaultFilters: ProcoreToolFilter[];
  bidGroupings: ProcoreToolGroupings[];
};

export type ProcoreFilterBidData = {
  bids: ProcoreBid[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreBidVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterBidVariables = ProcoreBidVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const BidFragment = gql`
fragment BidFragment on ProcoreBid {
  id
  procoreServerId
  formattedTitle
  vendorName
  bidPackageTitle
  description
}
`;

export const filterProcoreBidsQuery = gql`
query FilterProcoreBids($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  bids(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...BidFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${BidFragment}
`;

export const fetchPaginatedProcoreBidsQuery = gql`
query FilterPaginatedProcoreBids($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  bids(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...BidFragment
  }
}
${BidFragment}
`;