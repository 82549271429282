import {
  Box,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  Typography,
  styled,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import React from 'react';
import { AppContainer, Colors } from '../../styles';
import Loading from '../shared/Loading';
import TeamHome from './TeamHome';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useGetCurrentUser } from '../../graphql/queries/GetCurrentUser';
import { AuthContext } from '../../contexts/AuthContext';
import { AccountProjectContext } from '../../contexts/AccountProjectContextProvider';
import { isEmpty } from '../../utils/utils';

const TeamTab = styled(Tab)<TabProps>(() => {
  return {
    fontSize: '13px',
    fontWeight: 400,
    minHeight: '24px',
    lineHeight: '15px',
    padding: 0,
    color: Colors.darkerGray,
    opacity: 1,
    maxWidth: '250px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiTab-wrapped': {
      width: '145px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      paddingLeft: '8px',
    },
    '&.Mui-selected': {
      fontWeight: 700,
      opacity: 1,
    },
  };
});

const TeamTabs = styled(Tabs)<TabsProps>(() => {
  return {
    marginTop: '12px',
    gap: '8px',
    '& .MuiTabs-flexContainer': {
      gap: '8px',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: Colors.darkerGray,
      right: 'unset',
      width: '6px',
      height: '24px',
    },
  };
});

const TeamsContainer = (): JSX.Element => {
  const { redirectPathContext, setCurrentUserInfo } = React.useContext(AuthContext);
  const { state, dispatch } = React.useContext(AccountProjectContext);

  const { loading: loadingCurrentUser, data: currentUserData } = useGetCurrentUser();

  React.useEffect(() => {
    if (state.loading || state.accounts.length === 0) {
      return;
    }

    if (state.accounts.length > 0 && !state.accounts.map(account => account.id).includes(state.selectedAccount)) {
      if (redirectPathContext.accountId && state.accounts.map(account => account.id).includes(redirectPathContext.accountId)) {
        dispatch({
          type: 'SET_SELECTED_ACCOUNT',
          value: redirectPathContext.accountId,
        });
      } else {
        dispatch({
          type: 'SET_SELECTED_ACCOUNT',
          value: state.accounts[0].id,
        });
      }
    }
  }, [state.accounts]);

  React.useEffect(() => {
    if (loadingCurrentUser || !currentUserData) {
      return;
    }

    setCurrentUserInfo(
      currentUserData.currentUser.id,
      currentUserData.currentUser.procoreUserEmail,
      currentUserData.currentUser.createdAt
    );
  }, [loadingCurrentUser]);

  const handleChange = (event, newValue) => {
    dispatch({
      type: 'SET_SELECTED_ACCOUNT',
      value: newValue
    });
  };

  if (state.loading || state.accounts.length === 0) {
    return <Loading loadingLabel="Loading teams..." />;
  }

  const hideTeamsList = state.accounts.length === 1 && !state.accounts[0].setup;

  return (
    <Box height={1} display="flex" flexDirection="column" gap="8px">
      <AppContainer maxWidth="lg">
        <TabContext value={state.selectedAccount}>
          <Box
            display="flex"
            flexDirection={'row'}
            height={1}
            width={1}
            gap={'36px'}
          >
            <Box
              display={hideTeamsList ? 'none' : 'flex'}
              flexDirection={'column'}
              marginTop={'22px'}
            >
              <Typography
                style={{
                  color: Colors.darkishGray,
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: '14px',
                }}
              >
                Teams
              </Typography>
              <TeamTabs
                orientation="vertical"
                value={state.selectedAccount}
                onChange={handleChange}
              >
                {isEmpty(state.selectedAccount) && <TeamTab value="" label="" />}
                {state.accounts.map((account) => {
                  if (account.setup) {
                    return (
                      <TeamTab
                        wrapped={true}
                        label={
                          <span
                            style={{
                              paddingLeft: '18px',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width: '100%',
                              height: '100%',
                            }}
                          >{`${account.name}${
                            account.personal ? '' : ' (Shared)'
                          }`}</span>
                        }
                        key={`account-tab-${account.id}`}
                        value={account.id}
                      />
                    );
                  } else {
                    return (
                      <TeamTab
                        wrapped={true}
                        key={`account-tab-${account.id}`}
                        label={
                          <span
                            style={{
                              paddingLeft: '18px',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            {'New Team'}
                          </span>
                        }
                        icon={
                          <AddCircleOutlineOutlinedIcon
                            style={{ margin: 0, marginLeft: '16px', fontSize: '1rem' }}
                          />
                        }
                        value={account.id}
                      ></TeamTab>
                    );
                  }
                })}
              </TeamTabs>
            </Box>
            <Box display="flex" width={1} marginTop={'12px'}>
              {state.accounts.map((account) => {
                return (
                  <TabPanel
                    style={{
                      padding: 0,
                      display:
                      state.selectedAccount === account.id ? 'flex' : 'none',
                      flexDirection: 'column',
                      paddingTop: 8,
                      flex: '1 1 1px',
                      width: '100%',
                      position: 'relative',
                    }}
                    key={`account-tab-panel-${account.id}`}
                    value={account.id}
                  >
                    <TeamHome
                      account={account}
                      handleLicenseUsed={(accountId, numberOfLicenses) => {
                        const newAccounts = state.accounts.reduce((acc, next) => {
                          if (next.id === accountId) {
                            acc.push({
                              ...next,
                              numberOfLicenses: numberOfLicenses,
                            });
                          } else {
                            acc.push(next);
                          }

                          return acc;
                        }, []);
                        dispatch({ type: 'SET_ACCOUNTS', value: { accounts: newAccounts } });
                      }}
                      handleAccountNamed={(accountId, newName) => {
                        const newAccounts = state.accounts.reduce((acc, next) => {
                          if (next.id === accountId) {
                            acc.push({
                              ...next,
                              name: newName,
                              setup: true,
                            });
                          } else {
                            acc.push(next);
                          }

                          return acc;
                        }, []);

                        dispatch({ type: 'SET_ACCOUNTS', value: { accounts: newAccounts } });
                      }}
                      handleConfiguredAutomations={(accountId) => {
                        const newAccounts = state.accounts.reduce((acc, next) => {
                          if (next.id === accountId) {
                            acc.push({
                              ...next,
                              configuredAutomations: true,
                            });
                          } else {
                            acc.push(next);
                          }

                          return acc;
                        }, []);

                        dispatch({ type: 'SET_ACCOUNTS', value: { accounts: newAccounts, selectedTab: 'automations' } });
                      }}
                      handleFreeTrialUsed={(accountId) => {
                        const newAccounts = state.accounts.reduce((acc, next) => {
                          if (next.id === accountId) {
                            acc.push({
                              ...next,
                              freeTrialAvailable: false,
                            });
                          } else {
                            acc.push(next);
                          }

                          return acc;
                        }, []);
                        dispatch({ type: 'SET_ACCOUNTS', value: { accounts: newAccounts } });
                      }}
                    />
                  </TabPanel>
                );
              })}
              <TabPanel value={""} />
            </Box>
          </Box>
        </TabContext>
      </AppContainer>
    </Box>
  );
};

export default TeamsContainer;
