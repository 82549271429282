import {
  Box,
  DialogContent,
  MenuItem,
  Popover,
  SvgIconProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { useState } from "react";
import { Package } from "../../types";
import ListEmptyState from "./PackageListEmptyState";
import Loading from "../shared/Loading";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Tooltip } from "@mui/material";
import { AccountProjectContext } from "../../contexts/AccountProjectContextProvider";
import { AuthContext } from "../../contexts/AuthContext";
import { getStatusCodeFromApolloError } from "../../utils/utils";
import idx from "idx";
import { Colors, DialogDivider, MyDialog, MyDialogActions, PrimaryTypography, SecondarySettingsButton, SecondaryTypography } from "../../styles";
import useNavigationHook from "../../hooks/UseNavigationHook";
import { PackageHistoryContext } from "../../contexts/PackageContextProvider";
import StatusPill from "../shared/StatusPill";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDiscardPackage } from "../../graphql/mutations/DiscardPackage";
import { MyDialogTitle } from "../package/NewPackage";
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import { Button } from "../shared/Button";
import { formatDate } from "../../utils/utils";
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const CustomSaveAltIcon = styled(SaveAltIcon)<SvgIconProps>(() => {
  return {
    width: ".85em",
    height: ".85em",
  };
});

export const CustomRepeatOutlinedIcon = styled(RepeatOutlinedIcon)<SvgIconProps>(() => {
  return {
    width: ".85em",
    height: ".85em",
  };
});

export const CustomOpenInNewOutlinedIcon = styled(OpenInNewOutlinedIcon)<SvgIconProps>(() => {
  return {
    width: ".85em",
    height: ".85em",
  };
});

export const TableHeaderCell = styled(TableCell)<TableCellProps>(() => {
  return {
    paddingTop: "12px",
    paddingBottom: "12px",
    borderBottom: "1px solid #EDF2F5",
    color: "#1A2024",
    '&.MuiTableCell-stickyHeader': {
      backgroundColor: "white",
    }
  };
});

export const TableRowCell = styled(TableCell)<TableCellProps>(() => {
  return {
    borderBottom: `1px solid ${Colors.lightishGray}`,
    color: Colors.darkerGray,
  };
});

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 8px 28px 0px rgba(26, 32, 36, 0.30)',
    borderRadius: '8px',
    padding: 0,
    margin: 0
  },
}));

const PackageList = (): JSX.Element => {
  const authState = React.useContext(AuthContext);
  const navigateTo = useNavigationHook();
  const { state: accountProjectState } = React.useContext(AccountProjectContext);
  const { state, fetchPackages } = React.useContext(PackageHistoryContext);
  const [morePackageOptionsAnchorEl, setMorePackageOptionsAnchorEl] =
    useState(null);
  const [confirmArchiveModalOpen, setConfirmArchiveModalOpen] = useState(false);
  const [currentPackageId, setCurrentPackageId] = useState(null);
  const [discardPackageLoading, setDiscardPackageLoading] = useState(false);

  const [discardPackage] = useDiscardPackage({
    id: -1,
    accountId: accountProjectState.accountId,
    procoreProjectServerId: accountProjectState.procoreProjectServerId
  });

  const resetPackageArchiveState = () => {
    setCurrentPackageId(null);
    setMorePackageOptionsAnchorEl(null);
  }

  React.useEffect(() => {
    if (state.error) {
      switch (getStatusCodeFromApolloError(state.error)) {
        case 401: {
          authState.setAuthenticated(false);
          navigateTo("/login");
          break;
        }
        case 403: {
          authState.setAuthenticated(false);
          navigateTo("/error");
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [state.error]);

  const getStatus = (status: string) => {
    switch (status) {
      case "in_progress":
        return "Extracting";
      case "complete":
        return "Done";
      case "zipping":
        return "Zipping";
      case "uploading":
        return "Uploading";
      case "canceled":
        return "Canceled";
      case 'failed_size_limit_exceeded':
        return 'Failed';
      default:
        return "";
    }
  };

  const openExportUrl = (pkg: Package) => {
    if (pkg.downloadExportUrl) {
      window.open(pkg.downloadExportUrl, "_blank");
    }
  };

  const editAndRerun = (pkg: Package) => {
    navigateTo(`/teams/${accountProjectState.accountId}/projects/${accountProjectState.procoreProjectServerId}/packages/new?packageId=${pkg.id}`)
  };

  const packages = (idx(state.data, (data) => data.packages) ||
    []) as Package[];

  return state.loading ? (
    <Loading loadingLabel={"Loading packages..."} />
  ) : (
    <Box
      display="flex"
      width={1}
      height={1}
      flexDirection="column"
      borderRadius="4px"
      style={{ background: "white" }}
    >
      <TableContainer
        style={{
          background: "white",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          minHeight: '100px',
          flex: '1 1 1px'
        }}
      >
        <Table stickyHeader aria-label="simple table" size="medium">
          <TableHead>
            <TableRow>
              <TableHeaderCell component="th">Name</TableHeaderCell>
              <TableHeaderCell component="th" align="left">
                Date Created
              </TableHeaderCell>
              <TableHeaderCell component="th" align="left">
                Status
              </TableHeaderCell>
              <TableHeaderCell component="th" align="left"></TableHeaderCell>
              <TableHeaderCell component="th" align="left"></TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages.map((pkg) => {
              const packageQueryGroupings = [...new Set(pkg.packageQueries)].reduce((acc, nextItem) => {
                acc[nextItem.toolTitle] ||= 0;

                acc[nextItem.toolTitle] += nextItem.selectedItemsCount;

                return acc;
              }, {});

              return (
                <TableRow key={pkg.id}>
                  <TableRowCell
                    component="td"
                    scope="row"
                    style={{ maxWidth: "250px" }}
                  >
                    <Box display={"flex"} flexDirection={"column"}>
                      <PrimaryTypography
                        variant="h6"
                        style={{ fontSize: "1rem" }}
                      >
                        {pkg.name}
                      </PrimaryTypography>
                      {Object.keys(packageQueryGroupings).map((procoreToolTitle) => {
                        const value = packageQueryGroupings[procoreToolTitle];

                        return (
                          <SecondaryTypography
                            noWrap
                            key={`package-query-filters-${procoreToolTitle}`}
                            style={{ fontSize: "12px", marginTop: "4px" }}
                          >
                            {`${procoreToolTitle} (${value})`}
                          </SecondaryTypography>
                        );
                      })}
                    </Box>
                  </TableRowCell>
                  <TableRowCell component="td" align="left">
                    <PrimaryTypography
                      variant="body1"
                      style={{ fontSize: "0.875rem" }}
                    >
                      {formatDate(pkg.createdAt, accountProjectState.locale, accountProjectState.timeZone)}
                    </PrimaryTypography>
                  </TableRowCell>
                  <TableRowCell component="td" align="left">
                    {
                      pkg.status === 'failed_size_limit_exceeded' ? (
                        <LightTooltip title={
                          <React.Fragment>
                            <Box display={"flex"} flexDirection={"column"} gap={"12px"} padding={"20px 24px"}>
                              <Box display={"flex"} gap="6px" alignItems={"center"} flexDirection={"row"}>
                                <ReportGmailerrorredOutlinedIcon sx={{ color: '#991A1B' }} />
                                <Typography style={{ fontSize: '14px', fontWeight: 500, color: Colors.darkerGray }}>
                                  {'Size Limit Exceeded'}
                                </Typography>
                              </Box>
                              <Typography style={{ fontSize: '12px', color: Colors.darkishGray }}>
                                {`Our system can not process extracts that are more than 30 GB or 10,000 files (including attachments).`}
                              </Typography>
                              <Typography style={{ fontSize: '12px', color: Colors.darkishGray }}>
                                {`Try splitting up the currently selected files into multiple extracts.`}
                              </Typography>
                            </Box>
                          </React.Fragment>
                        }>
                          <Box
                            margin={0}
                            fontWeight={'bold'}
                            display="inline-block"
                            style={{
                              color: '#991A1B',
                              backgroundColor: '#FFE5E9',
                              fontSize: '14px',
                              textAlign: 'center',
                              padding: '4px 10px',
                              borderRadius: '6px'
                            }}
                          >
                            <Typography sx={{
                              color: '#991A1B',
                              fontSize: '14px',
                              fontWeight: 'bold',
                              display: 'inline-block',
                              marginRight: '3px',
                              verticalAlign: 'middle'
                            }}>{getStatus(pkg.status)}</Typography>
                            <InfoOutlinedIcon fontSize="small" sx={{ color: '#991A1B', verticalAlign: 'middle', display: 'inline-block' }} />
                          </Box>
                        </LightTooltip>
                        ) : (
                      <StatusPill status={pkg.status}>
                        {getStatus(pkg.status)}
                      </StatusPill>
                      )
                    }
                  </TableRowCell>
                  {(pkg.status !== 'complete' || !pkg.downloadExportUrl) ? (
                    <>
                      <TableRowCell component="td" align="left" colSpan={3} />
                    </>
                  ) : (
                    <>
                      <TableRowCell component="td" align="right"></TableRowCell>
                      <TableRowCell component="td" align="right">
                        <Box display="flex"
                            justifyContent={"center"}
                            alignItems={"center"}
                            style={{ gap: '8px' }}>
                          <Tooltip
                            arrow={true}
                            placement="bottom"
                            TransitionProps={{ timeout: 600 }}
                            disableHoverListener={pkg.status !== 'complete' || !pkg.downloadExportUrl}
                            disableFocusListener={true}
                            disableTouchListener={true}
                            title={pkg.exportTo === 'zip_file' ? pkg.exportSize : 'Open folder in Procore'}
                          >
                            <SecondarySettingsButton
                              disabled={pkg.status !== 'complete' || !pkg.downloadExportUrl}
                              onClick={() => openExportUrl(pkg)}
                              variant="outlined"
                              disableElevation={true}
                              size="small"
                            >
                              {
                                pkg.exportTo === 'zip_file' ? (
                                  <CustomSaveAltIcon />
                                ) : (
                                  <CustomOpenInNewOutlinedIcon />
                                )
                              }
                            </SecondarySettingsButton>
                          </Tooltip>
                          {
                            accountProjectState.licenseType !== 'trial' && pkg.packageType == 'instant' && (
                              <Tooltip
                                arrow={true}
                                placement="bottom"
                                TransitionProps={{ timeout: 600 }}
                                disableHoverListener={pkg.status !== 'complete' || !pkg.downloadExportUrl}
                                disableFocusListener={true}
                                disableTouchListener={true}
                                title={'Edit & rerun'}
                              >
                                <SecondarySettingsButton
                                  variant="outlined"
                                  disableElevation={true}
                                  size="small"
                                  disabled={pkg.status !== 'complete'}
                                  onClick={() => editAndRerun(pkg)}
                                >
                                  {
                                    <CustomRepeatOutlinedIcon />
                                  }
                                </SecondarySettingsButton>
                              </Tooltip>
                            )
                          }
                          {
                            accountProjectState.licenseType !== 'trial' && (
                              <SecondarySettingsButton
                                variant="outlined"
                                disableElevation={true}
                                size="small"
                                onClick={(event) => {
                                  setCurrentPackageId(pkg.id);
                                  setMorePackageOptionsAnchorEl(event.currentTarget);
                                }}
                                startIcon={<MoreVertIcon />}
                              ></SecondarySettingsButton>
                            )
                          }
                        </Box>
                      </TableRowCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {packages.length === 0 && <ListEmptyState />}

      <Popover
        open={Boolean(morePackageOptionsAnchorEl)}
        anchorEl={morePackageOptionsAnchorEl}
        onClose={() => resetPackageArchiveState()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          key="archive-package"
          onClick={() => {
            setMorePackageOptionsAnchorEl(null);

            setConfirmArchiveModalOpen(true)
          }}
        >
          {"Archive"}
        </MenuItem>
      </Popover>

      <MyDialog
        maxWidth={'sm'}
        open={confirmArchiveModalOpen}>
        <MyDialogTitle>
          {"Archive Package"}
        </MyDialogTitle>
        <DialogContent dividers={false} style={{ marginBottom: '20px'}}>
          <Typography gutterBottom>
            {`Are you sure you want to archive this package?`}
          </Typography>
        </DialogContent>
        <DialogDivider />
        <MyDialogActions>
          <Button
            autoFocus
            onClick={() => {
              resetPackageArchiveState();
              setConfirmArchiveModalOpen(false);
            }}
            variant="outlined"
            disabled={discardPackageLoading}
            disableElevation={true}
            size="large">
            {"Cancel"}
          </Button>
          <Button
            disabled={discardPackageLoading}
            onClick={() => {
              setDiscardPackageLoading(true);

              discardPackage({ variables: {
                id: currentPackageId,
                accountId: accountProjectState.accountId,
                procoreProjectServerId: accountProjectState.procoreProjectServerId
              }}).then(() => {
                setDiscardPackageLoading(false);
                resetPackageArchiveState();
                setConfirmArchiveModalOpen(false);
                fetchPackages();
              }).catch(() => {
                setDiscardPackageLoading(false);
                resetPackageArchiveState();
                setConfirmArchiveModalOpen(false);
              })
            }}
            size="large"
            variant="contained"
            disableElevation={true}
            style={{ backgroundColor: Colors.red, color: Colors.white }}>
            {'Archive'}
          </Button>
        </MyDialogActions>
      </MyDialog>
    </Box>
  );
};

export default PackageList;
