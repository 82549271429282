import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import idx from "idx";
import React, { useState, useContext } from "react";
import {
  Action,
  ProcoreItem,
  ProcoreToolFilter,
  ToolTabData,
} from "../../contexts/NewPackageContext";
import { NewPackageContext } from "../../contexts/NewPackageContextProvider";
import { Colors } from "../../styles";
import { isSelectFilter, SelectFilterValue } from "../../types/submittal";
import Loading from "../shared/Loading";
import {
  FilterSelect,
  FilterSelectInput,
} from "./styles";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { BaseProcoreType, FilterOptionInput } from "../../types";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ApolloClient, DocumentNode, OperationVariables } from "@apollo/client";
import { PAGINATION, TOOL_ENGINE_NAMES, checkedItems } from "../../utils/utils";
import { AccountProjectContext } from "../../contexts/AccountProjectContextProvider";
import { AccountProjectState } from "../../contexts/AccountProjectContext";
import { FilterAutocomplete } from "../shared/FilterAutocomplete";
import { FilterDateRangeSelect } from "../shared/FilterDateRangeSelect";
import { AddFilterAutocomplete } from "../shared/AddFilterAutocomplete";
import { formatISO, isAfter } from "date-fns";
import { ProcoreFilterDailyLogData, ProcoreFilterDailyLogVariables, fetchPaginatedProcoreDailyLogsQuery } from "../../graphql/queries/GetProcoreDailyLogs";
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import { ProcoreDailyLog } from "../../types/daily_log";
import { ListItemText, ListItemTextProps, styled } from "@mui/material";

const CustomListItemText = styled(ListItemText)<ListItemTextProps>(() => {
  return {
    color: Colors.darkGray,
    fontSize: "1.125em",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
});

function fetchPaginatedItems<TData, TVariables = OperationVariables>(
  query: DocumentNode,
  toolTabIndex: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  dispatch: (action: Action) => void,
  variables: () => TVariables,
  onSuccess: (value: TData) => ProcoreItem[]) {

  dispatch({
    type: "SET_TOOL_TAB_LOADING_MORE",
    value: {
      toolTabIndex: toolTabIndex,
      loadingMore: true,
    },
  });

  client
    .query<TData, TVariables>({
      query: query,
      variables: variables(),
      fetchPolicy: "cache-first",
    })
    .then((result) => {
      dispatch({
        type: "APPEND_PROCORE_ITEMS",
        value: {
          toolTabIndex: toolTabIndex,
          procoreItems: onSuccess(result.data),
        },
      });
      dispatch({
        type: "SET_TOOL_TAB_LOADING_MORE",
        value: {
          toolTabIndex: toolTabIndex,
          loadingMore: false,
        },
      });
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: "SET_TOOL_TAB_LOADING_MORE",
        value: {
          toolTabIndex: toolTabIndex,
          loadingMore: false,
        },
      });

      dispatch({
        type: "SET_TOOL_TAB_FAILED_TO_FETCH_MORE",
        value: {
          toolTabIndex: toolTabIndex,
          failedToFetchMore: true,
        },
      });
    });
}

const queryProcoreItemForTool = (
  toolTabData: ToolTabData,
  toolTabIndex: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  dispatch: (action: Action) => void,
  accountProjectState: AccountProjectState,
  filters: FilterOptionInput[]
) => {
  if (toolTabData.procoreTool === null || toolTabData.procoreTool === undefined) {
    return;
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DAILY_LOG) {
    fetchPaginatedItems<ProcoreFilterDailyLogData, ProcoreFilterDailyLogVariables>(
      fetchPaginatedProcoreDailyLogsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.dailyLogs,
    )
  }
};

export interface DailyLogFilterOptionsProps {
  toolTabIndex: number;
  dateFormat: string;
  filters: ProcoreToolFilter[];
  toolTabData: ToolTabData;
  onFilterOptionSelectionChange: ({
    selectedFilters,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => void;
}

export type DailyLogRowType = {
  type: 'daily_log_type' | 'daily_log_header';
  procoreServerId?: number;
  formattedTitle: string;
}

const ToolSelectionRequired = (props: {
  toolTabIndex: number;
}): JSX.Element => {
  const { state, dispatch } = useContext(NewPackageContext);
  const usedTools = state.toolTabs
    .filter((t) => t.procoreTool !== null)
    .map((t) => t.procoreTool);

  const handleToolChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    const procoreToolId = event.target.value as number;

    const procoreTool = state.procoreTools.find(
      (tool) => tool.id === procoreToolId
    );
    if (procoreTool) {
      dispatch({
        type: "SET_TOOL",
        value: {
          toolTabIndex: props.toolTabIndex,
          procoreTool: procoreTool,
        },
      });
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{ backgroundColor: Colors.lightestGray }}
      height={1}
      width={1}
    >
      <Typography variant="h6" style={{ marginBottom: "20px" }}>
        {"Select a tool to query"}
      </Typography>
      <FilterSelect
        key={`procore-tool-filter-select`}
        input={<FilterSelectInput />}
        autoWidth={true}
        style={{ marginBottom: "16px" }}
        displayEmpty={true}
        // TODO: Investigate
        onChange={handleToolChanged}
        value={""}
        defaultValue={"Select a tool..."}
        renderValue={() => `Select a tool...`}
        label="Tool"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          PaperProps:{
           sx: {
            maxHeight: '400px'
           }
          }
        }}
      >
        {state.procoreTools.map((procoreTool) => {
          const disabledMenuItem = usedTools.some(t => t.id === procoreTool.id);
          return (
            <MenuItem
              disabled={disabledMenuItem}
              key={procoreTool.id}
              value={procoreTool.id}
            >
              {procoreTool.title}
            </MenuItem>
          );
        })}
      </FilterSelect>
    </Box>
  );
};

const DailyLogFilterOptions = ({
  filters,
  toolTabIndex,
  toolTabData,
  dateFormat,
  onFilterOptionSelectionChange,
}: DailyLogFilterOptionsProps) => {
  const { dispatch } = useContext(NewPackageContext);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    toolTabData.selectedFilters.map((f) => f.label)
  );

  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    Record<string, string[]>
  >(
    toolTabData.selectedFilters.reduce((acc, current) => {
      if (isSelectFilter(current)) {
        acc[current.label] = current.values.map((v) => v.value);
        return acc;
      } else {
        const values = [];

        if (current?.value?.startDate) {
          values.push(formatISO(current.value.startDate))
        }

        if (current?.value?.endDate) {
          values.push(formatISO(current.value.endDate));
        }
        acc[current.label] = values;
        return acc;
      }
    }, {})
  );

  const dispatchSetSelectedFilters = (
    selectedFilters: string[],
    selectedFilterOptions: Record<string, string[]>,
    triggerFilterOptionSelectionChange = false
  ) => {
    const newSelectedFilters: ProcoreToolFilter[] = selectedFilters.map(
      (selectedFilter) => {
        const filter: ProcoreToolFilter = filters.find(
          (f) => f.label === selectedFilter
        );

        if (isSelectFilter(filter)) {
          return {
            ...filter,
            values: filter.values.filter((v) =>
              (selectedFilterOptions[filter.label] || []).includes(v.value)
            ),
          };
        } else {
          const [startDate, endDate] =
            selectedFilterOptions[filter.label] || [];
          return {
            ...filter,
            value: {
              startDate: startDate ? new Date(startDate) : undefined,
              endDate: endDate ? new Date(endDate) : undefined,
            },
          };
        }
      }
    );

    dispatch({
      type: "SET_SELECTED_FILTERS",
      value: {
        toolTabIndex: toolTabIndex,
        selectedFilters: newSelectedFilters,
      },
    });

    if (triggerFilterOptionSelectionChange) {
      onFilterOptionSelectionChange({ selectedFilters: newSelectedFilters });
    }
  };

  const handleAddFilterSelectChanged = (
    newFilters: string[],
    triggerFilterOptionSelectionChange = false
  ) => {
    const selectedFilterLength = selectedFilters.length;

    // If there are new filters then we can close the AddFilterSelect
    // set all other existings filters open to false and the new 1 to true
    // We'll have to manually manage open/close state for each filter.
    if (newFilters.length > selectedFilterLength) {
      const newFilterOpenState = newFilters.reduce((acc, filterLabel) => {
        const alreadyExists = selectedFilters.includes(filterLabel);
        acc[filterLabel] = !alreadyExists;

        return acc;
      }, {} as Record<string, boolean>);

      dispatch({
        type: "SET_FILTER_OPEN_STATE",
        value: {
          toolTabIndex: toolTabIndex,
          filterOpenState: newFilterOpenState,
        },
      });
    } else {
      const newFilterOpenState = newFilters.reduce((acc, filterLabel) => {
        acc[filterLabel] = false;
        return acc;
      }, {} as Record<string, boolean>);

      dispatch({
        type: "SET_FILTER_OPEN_STATE",
        value: {
          toolTabIndex: toolTabIndex,
          filterOpenState: newFilterOpenState,
        },
      });
    }

    setSelectedFilters(newFilters);
    const newSelectedFilterOptions = newFilters.reduce((acc, filterLabel) => {
      acc[filterLabel] = selectedFilterOptions[filterLabel] || [];

      return acc;
    }, {} as Record<string, string[]>);

    setSelectedFilterOptions(newSelectedFilterOptions);

    dispatchSetSelectedFilters(
      newFilters,
      newSelectedFilterOptions,
      triggerFilterOptionSelectionChange
    );
  };

  const handleSelectedFilterOptionsChanged = (
    values: SelectFilterValue[],
    filterLabel: string
  ) => {
    const state = {
      ...selectedFilterOptions,
      [filterLabel]: values.map(v => v.value),
    };
    setSelectedFilterOptions(state);

    dispatchSetSelectedFilters(selectedFilters, state);
  };

  const onFilterClose = (filterLabel: string) => {
    dispatch({
      type: "SET_FILTER_OPEN_STATE",
      value: {
        toolTabIndex: toolTabIndex,
        filterOpenState: {
          ...toolTabData.filterOpenState,
          [filterLabel]: false
        },
      },
    });

    onFilterOptionSelectionChange({ selectedFilters: undefined });
  };

  const onFilterOpen = (filterLabel: string) => {
    dispatch({
      type: "SET_FILTER_OPEN_STATE",
      value: {
        toolTabIndex: toolTabIndex,
        filterOpenState: {
          ...toolTabData.filterOpenState,
          [filterLabel]: true
        },
      },
    });
  };

  const logDateFilter = filters.find((f) => f.key === 'log_date');
  const logDateFilterOptions = (logDateFilter ? selectedFilterOptions[logDateFilter.label] : []) || [];

  const value: DateRange<Date | null> = logDateFilter ? [
    logDateFilterOptions[0] ? new Date(logDateFilterOptions[0]) : null,
    logDateFilterOptions[1] ? new Date(logDateFilterOptions[1]) : null
  ] : [null, null];

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexWrap="wrap"
      margin={1}
      style={{
        gap: '4px'
      }}
    >
      <DateRangePicker
        format={dateFormat}
        value={value}
        maxDate={new Date()}
        disableFuture={true}
        onClose={() => {
          onFilterOptionSelectionChange({ selectedFilters: undefined });
        }}
        closeOnSelect={false}
        sx={{
          marginRight: '16px'
        }}
        slotProps={{
          textField: {
            size: 'small',
          },
          dialog: {
            disableScrollLock: true,
          }
        }}
        onChange={(newValue) => {
          const filter = toolTabData.selectedFilters.find(f => f.key === 'log_date');

          if (newValue[0] && isAfter(newValue[0], new Date())) {
            const state = {
              ...selectedFilterOptions,
              [filter.label]: [formatISO(new Date()), formatISO(new Date())],
            };

            setSelectedFilterOptions(state);

            dispatchSetSelectedFilters(selectedFilters, state);
          } else if (newValue[1] && isAfter(newValue[1], new Date())) {
            const state = {
              ...selectedFilterOptions,
              [filter.label]: [formatISO(newValue[0]), formatISO(new Date())],
            };

            setSelectedFilterOptions(state);

            dispatchSetSelectedFilters(selectedFilters, state);
          } else {
            const state = {
              ...selectedFilterOptions,
              [filter.label]: [formatISO(newValue[0]), formatISO(newValue[1])],
            };

            setSelectedFilterOptions(state);

            dispatchSetSelectedFilters(selectedFilters, state);
          }
        }}
        localeText={{ start: 'Start Date', end: 'End Date' }}
      />
      <Divider orientation="vertical" style={{ height: '39px'}} />
      <AddFilterAutocomplete
        options={filters.filter((f) => f.key !== 'log_date').filter((f) => f?.values?.length > 0 || f.type === "daterange").map(f => f.label)}
        selectedOptions={selectedFilters.filter(label => {
          return filters.filter((f) => f.key !== 'log_date').map(f => f.label).includes(label);
        })}
        key={`grouping-select-${toolTabData.procoreTool.id}`}
        onClose={() => { /** Do Nothing */ }}
        onChange={(values) => {
          const logDateFilter = filters.find((f) => f.key === 'log_date').label;
          handleAddFilterSelectChanged([logDateFilter, ...values], false)
        }}
        filterLabel={"Add Filters"}
      />
      {selectedFilters.map((filterName) => {
        const filter = filters.filter((f) => f.label === filterName)[0];

        if (filter.key === 'log_date') { return null; }

        switch (filter.type) {
          case "select": {
            if (filter.multiple == false) {
              return (
                <FilterAutocomplete
                  disabled={false}
                  key={`select-single-${filter.label}`}
                  filterLabel={filter.label}
                  open={toolTabData.filterOpenState[filter.label] || false}
                  onOpen={() => onFilterOpen(filter.label)}
                  onClose={() => {
                    dispatch({
                      type: "SET_FILTER_OPEN_STATE",
                      value: {
                        toolTabIndex: toolTabIndex,
                        filterOpenState: {
                          ...toolTabData.filterOpenState,
                          [filter.label]: false
                        },
                      },
                    });
                  }}
                  removeFilter={() => {
                    handleAddFilterSelectChanged(
                      selectedFilters.filter((f) => f !== filter.label),
                      true
                    );
                  }}
                  options={filter.values || []}
                  selectedOptions={toolTabData.selectedFilters.find(f => f.label === filter.label)?.values || []}
                  onChange={(values) => {
                    const state = {
                      ...selectedFilterOptions,
                      [filter.label]: values.map(v => v.value),
                    };
                    setSelectedFilterOptions(state);

                    dispatch({
                      type: "SET_FILTER_OPEN_STATE",
                      value: {
                        toolTabIndex: toolTabIndex,
                        filterOpenState: {
                          ...toolTabData.filterOpenState,
                          [filter.label]: false
                        },
                      },
                    });

                    dispatchSetSelectedFilters(selectedFilters, state, true);
                  }}
                />
              );
            } else {
              return (
                <FilterAutocomplete
                  disabled={false}
                  key={`select-multiple-${filter.label}`}
                  filterLabel={filter.label}
                  open={toolTabData.filterOpenState[filter.label] || false}
                  onOpen={() => onFilterOpen(filter.label)}
                  onChange={(values) => {
                    handleSelectedFilterOptionsChanged(values, filter.label)
                  }}
                  selectedOptions={toolTabData.selectedFilters.find(f => f.label === filter.label)?.values || []}
                  onClose={() => onFilterClose(filter.label)}
                  options={filter.values || []}
                  removeFilter={() =>
                    handleAddFilterSelectChanged(
                      selectedFilters.filter((f) => f !== filter.label),
                      true
                    )
                  }
                />
              );
            }
          }

          case "daterange": {
            const [startDate, endDate] =
              selectedFilterOptions[filter.label] || [];
            const initialDateRange = {
              startDate: startDate ? new Date(startDate) : undefined,
              endDate: endDate ? new Date(endDate) : undefined,
            };
            return (
              <FilterDateRangeSelect
                key={`date-range-select-${filter.label}`}
                filterLabel={filter.label}
                open={toolTabData.filterOpenState[filter.label] || false}
                onOpen={() => onFilterOpen(filter.label)}
                onClose={() => onFilterClose(filter.label)}
                initialDateRange={initialDateRange}
                onChange={(values) => {
                  const state = {
                    ...selectedFilterOptions,
                    [filter.label]: values,
                  };
                  setSelectedFilterOptions(state);

                  dispatchSetSelectedFilters(selectedFilters, state);
                }}
                removeFilter={() =>
                  handleAddFilterSelectChanged(
                    selectedFilters.filter((f) => f !== filter.label),
                    true
                  )
                }
              />
            );
          }

          default:
            throw new Error("Unsupported type.");
        }
      }).filter((f) => f !== null)}
    </Box>
  );
};

const DailyLogFilter = (props: {
  disabled: boolean;
  toolTabIndex: number;
  toolTabData: ToolTabData;
  onFilterOptionSelectionChange: ({
    selectedFilters,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => void;
}): JSX.Element => {
  const { state: accountProjState } = useContext(AccountProjectContext);

  const { disabled, toolTabIndex, toolTabData, onFilterOptionSelectionChange } =
    props;
  const onlyCheckedSelectedItems = (idx(toolTabData, t => checkedItems(t.selectedItems)));

  const procoreItems = (idx(toolTabData, (data) => data.procoreItems) || []) as ProcoreDailyLog[];
  const filters = (idx(toolTabData, (data) => data.filters) || []);
  const displayLoadingSubLabel =
    (idx(toolTabData, (data) => data.packageResourceSync.status) || "") ===
    "pending";
  const loadingSubLabel = displayLoadingSubLabel
    ? "This can take up to a few minutes on a fresh project."
    : "";

  const dailyLogRowTypes: DailyLogRowType[] = procoreItems.reduce((acc, item) => {

    acc.push({
      type: 'daily_log_header',
      procoreServerId: item.procoreServerId,
      formattedTitle: item.formattedTitle,
    });

    return acc;
  }, []);

  function renderRow(props: ListChildComponentProps<BaseProcoreType>) {
    const { index, style } = props;

    const procoreItem: DailyLogRowType = dailyLogRowTypes[index];
    if (procoreItem) {
      switch (procoreItem.type) {
        case 'daily_log_header': {
          return (
            <div style={style}>
              <ListItem
                key={`daily-log-header-${procoreItem.procoreServerId}`}
              >
                <CustomListItemText
                  disableTypography={true}
                >
                  {procoreItem.formattedTitle}
                </CustomListItemText>
              </ListItem>
              <Divider
                key={`daily-log-list-item-divider-${procoreItem.procoreServerId}`}
              />
            </div>
          )
        }

        case 'daily_log_type': {
          return (
            <div style={style}>
              <ListItem
                style={{
                  paddingLeft: '16px',
                }}
                key={`daily-log-type-list-item-${procoreItem.procoreServerId}`}
              >
                <CustomListItemText
                  disableTypography={true}
                  key={`daily-log-type-list-item-text-${procoreItem.procoreServerId}`}
                >
                  {procoreItem.formattedTitle}
                </CustomListItemText>
              </ListItem>
              <Divider
                key={`daily-log-type-list-item-divider-${procoreItem.procoreServerId}`}
              />
            </div>
          )
        }
      }
    } else {
      return null;
    }
  }

  if (disabled) {
    return <Box
      width={1}
      height={1}
      style={{
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor:
          onlyCheckedSelectedItems.length === 0 ? "#E4EBF0" : "#D2E9FA",
        background:
          onlyCheckedSelectedItems.length === 0 ? "white" : "#F5FBFF",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ToolSelectionRequired toolTabIndex={toolTabIndex} />
    </Box>;
  }

  const virtualizedItemCount = (dailyLogRowTypes.length < toolTabData.filteredProcoreItemServerIds.length) ? dailyLogRowTypes.length + 1 : dailyLogRowTypes.length;

  return (
    <>
      <Box
        width={1}
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor:
            onlyCheckedSelectedItems.length === 0 ? "#E4EBF0" : "#D2E9FA",
          background:
            onlyCheckedSelectedItems.length === 0 ? "white" : "#F5FBFF",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "row",
          marginBottom: "12px",
          padding: "14px 20px",
          alignItems: "center",
          minHeight: "57px",
        }}
      >
        {onlyCheckedSelectedItems.length === 0 ? (
          <InfoOutlinedIcon
            style={{ marginRight: "10px" }}
            htmlColor="#B5D7F5"
          />
        ) : (
          <CheckCircleSharpIcon
            style={{ marginRight: "10px" }}
            htmlColor={Colors.newAccentBlue}
          />
        )}
        {onlyCheckedSelectedItems.length === 0 ? (
          <Typography
            style={{ fontSize: 14, fontWeight: 500, color: Colors.darkerGray }}
          >
            {`Set a date range to extract`}
          </Typography>
        ) : (
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={1}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: 700,
                  color: Colors.darkerGray,
                  marginRight: "6px",
                }}
              >
                {onlyCheckedSelectedItems.length}
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: Colors.darkerGray,
                }}
              >
                {`${toolTabData.procoreTool.title} will be extracted`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        style={{
          border: "solid 1px #E4EBF0",
          borderRadius: "6px",
        }}
        display="flex"
        flexDirection="column"
        height={1}
        width={1}
      >
        {toolTabData.loading ? (
          <Loading
            loadingLabel={"Loading items..."}
            loadingSubLabel={loadingSubLabel}
          />
        ) : (
          <>
            <DailyLogFilterOptions
              filters={[...filters] as ProcoreToolFilter[]}
              dateFormat={accountProjState.dateFormat}
              toolTabIndex={toolTabIndex}
              toolTabData={toolTabData}
              onFilterOptionSelectionChange={onFilterOptionSelectionChange}
            />
            <Divider />
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <div style={{ flex: "1 1 1px" }}>
                {virtualizedItemCount === 0 && toolTabData.selectedFilters.filter(f => f.key === 'log_date').filter(f => f.value.startDate && f.value.endDate).length === 0 && (
                  <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={1} width={1}>
                    <Typography>
                      {'Start by selecting a date range.'}
                    </Typography>
                  </Box>
                )}
                {virtualizedItemCount === 0 && toolTabData.selectedFilters.filter(f => f.key === 'log_date').filter(f => f.value.startDate && f.value.endDate).length > 0 && (
                  <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={1} width={1}>
                    <Typography>
                      {'No items found with the selected date range.'}
                    </Typography>
                  </Box>
                )}
                {virtualizedItemCount > 0 && (
                  <AutoSizer>
                    {({ height, width }) => (
                      <FixedSizeList
                        height={height}
                        width={width}
                        itemCount={virtualizedItemCount}
                        itemSize={46}
                      >
                        {renderRow}
                      </FixedSizeList>
                    )}
                  </AutoSizer>
                )}
              </div>
            </div>
            <Box padding={'8px'} borderTop={'1px solid rgb(228, 235, 240)'}>
              <Typography style={{
                fontSize: '12px',
                color: '#A0B0BA',
                textAlign: 'center',
                fontWeight: '400'
              }}>{`Showing ${toolTabData.procoreItems.length} of ${toolTabData.filteredProcoreItemServerIds.length} items`}</Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default DailyLogFilter;
