import React, { useState } from 'react';
import {
  NewLogRequirementType,
  ResponsibleContractor,
  SpecSection,
} from '../types';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import { useCreateLogRequirements } from '../graphql/mutations/closeout/CreateLogRequirements';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box'
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { Typography } from './shared/Typography';
import { LoadingButton } from './shared/Button';
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close';
import AddCircle from '@mui/icons-material/AddCircle';
import { Button } from './shared/Button';
import { logRequirementFrom } from '../utils/utils';
import { ExtractsCheckbox } from '../styles';
import { useCreateLogRequirementType } from '../graphql/mutations/closeout/CreateLogRequirementType';
import { CircularProgress } from '@mui/material';

interface INewRequirementModalProps {
  selectedResponsibleContractors: ResponsibleContractor[];
  selectedSpecSection?: SpecSection;
  addReqModalIsOpen: boolean;
  closeAddReqModal: () => void;
}

const getAddButtonText = (bulkCount: number) => {
  if (bulkCount > 1) {
    return 'Add ' + bulkCount + ' items';
  } else {
    return 'Add';
  }
};

const filter = createFilterOptions<NewLogRequirementType>();

const NewRequirementModal = (props: INewRequirementModalProps): JSX.Element => {
  const { state: closeoutLogState, dispatch } = React.useContext(CloseoutLogContext);
  const { state } = React.useContext(AccountProjectContext);

  const [selectedLogReqTypes, setSelectedLogReqTypes] = useState<NewLogRequirementType[]>([]);
  const [selectedLogReqContractors, setSelectedLogReqContractors] = useState<ResponsibleContractor[]>(props.selectedResponsibleContractors);
  const [logRequirementDescription, setLogRequirementDescription] = useState<string>('');
  const [selectedLogReqSpecSections, setSelectedLogReqSpecSections] = useState<SpecSection[]>([]);
  const [responsibleContractorInputValue, setResponsibleContractorInputValue] = useState<string>('');
  const [specSectionInputValue, setSpecSectionInputValue] = useState<string>('');
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [confirmCloseModal, setConfirmCloseModal] = useState<boolean>(false);

  const [createLogRequirements, { loading }] = useCreateLogRequirements({
    accountId: state.accountId,
    procoreProjectServerId: state.procoreProjectServerId,
    closeoutLogId: closeoutLogState.id,
    logRequirementAttributes: [],
  });

  const [ createLogRequirementType, { loading: loadingCreateLogRequirementType } ] = useCreateLogRequirementType({
    accountId: state.accountId,
    procoreProjectServerId: state.procoreProjectServerId,
    closeoutLogId: closeoutLogState.id,
    name: ''
  });

  const createAndSelectLogRequirementType = (name: string) => {
    createLogRequirementType({
      variables: {
        accountId: state.accountId,
        procoreProjectServerId: state.procoreProjectServerId,
        closeoutLogId: closeoutLogState.id,
        name: name,
      }
    }).then(({ data }) => {
      if (data.createLogRequirementType.success) {
        setSelectedLogReqTypes([
          ...selectedLogReqTypes,
          data.createLogRequirementType.logRequirementType
        ]);

        dispatch({
          type: 'ADD_AND_SORT_LOG_REQUIREMENT_TYPE',
          value: data.createLogRequirementType.logRequirementType
        });
      }
    });
  }

  const handleOnAddItemsClick = () => {
    if (requestInProgress) { return; }

    setRequestInProgress(true);

    const logRequirementAttributes = selectedLogReqTypes.reduce((acc, logReqType) => {
      selectedLogReqSpecSections.forEach((logReqSpecSection) => {
        selectedLogReqContractors.forEach((logReqContractor) => {
          acc.push(logRequirementFrom({
            logReqType: logReqType,
            logRequirementDescription: logRequirementDescription,
            specSection: logReqSpecSection,
            responsibleContractor: logReqContractor,
          }))
        });

        if (selectedLogReqContractors.length === 0){
          acc.push(logRequirementFrom({
            logReqType: logReqType,
            logRequirementDescription: logRequirementDescription,
            specSection: logReqSpecSection,
            responsibleContractor: null,
          }))
        }
      });

      if (selectedLogReqSpecSections.length === 0) {
        selectedLogReqContractors.forEach((logReqContractor) => {
          acc.push(logRequirementFrom({
            logReqType: logReqType,
            logRequirementDescription: logRequirementDescription,
            specSection: null,
            responsibleContractor: logReqContractor,
          }))
        });

        if (selectedLogReqContractors.length === 0){
          acc.push(logRequirementFrom({
            logReqType: logReqType,
            logRequirementDescription: logRequirementDescription,
            specSection: null,
            responsibleContractor: null,
          }))
        }
      }

      return acc;
    }, []);

    createLogRequirements({
      variables: {
        accountId: state.accountId,
        procoreProjectServerId: state.procoreProjectServerId,
        closeoutLogId: closeoutLogState.id,
        logRequirementAttributes: logRequirementAttributes,
      }
    }).then(({ data }) => {
        if (data.createLogRequirements.success) {
          dispatch({
            type:'APPEND_LOG_REQUIREMENTS',
            value: {
              logRequirements: data.createLogRequirements.logRequirements,
              logRequirementItems: data.createLogRequirements.logRequirementItems,
              triggeredFrom: 'APPEND_LOG_REQUIREMENTS'
            }
          });
          setSelectedLogReqTypes([]);
          setSelectedLogReqContractors([]);
          setLogRequirementDescription('');
          setSelectedLogReqSpecSections([]);
          setResponsibleContractorInputValue('');
          setSpecSectionInputValue('');
          handleOnClose()
        }

      setRequestInProgress(false);
    }).catch(() => {
      setRequestInProgress(false);
    })
  };

  const handleOnClose = () => {
    // TODO: Reset other state

    props.closeAddReqModal();
  }
  const checkFormDirty = () => {
    return (selectedLogReqTypes.length>0 || selectedLogReqContractors.length>0 || logRequirementDescription!=='' || selectedLogReqSpecSections.length > 0)
  }

  const checkDirtyAndClose = () => {
    if (checkFormDirty()) {
      setConfirmCloseModal(true);
    }
    else {
      handleOnClose();
    }
  }

  return (
    <>
      <Dialog
          fullWidth
          disableScrollLock
          maxWidth="xs"
          open={confirmCloseModal}
          onClose={() => setConfirmCloseModal(false)}
        >
        <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <Typography typestyle="xl">Unsaved Data</Typography>
          <Typography>{`If you leave this form the data you will lose any data you've entered. Would you like to leave anyway?`}</Typography>
        <Box display="flex" justifyContent="end" gap="8px">
          <Button
            variant="outlined"
            buttonborderstyle="pill"
            onClick={() => setConfirmCloseModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            buttonborderstyle="pill"
            onClick={handleOnClose}
          >
            Leave Anyway
          </Button>
        </Box>
      </DialogContent>
    </Dialog>

      <Dialog
        maxWidth={false}
        fullScreen
        open={props.addReqModalIsOpen}
        onClose={checkDirtyAndClose}
        PaperProps={{
          sx: {
            position: 'absolute',
            width: 500,
            bottom: '0px',
            top: '0px',
            right: '0px',
          },
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '4px 0px 8px 0px',
          }}>
            <Typography typestyle="xl">{'New Requirement(s)'}</Typography>
            <IconButton onClick={checkDirtyAndClose}>
                <Close sx={{fontSize: '18px'}}/>
              </IconButton>
          </Box>
          <Autocomplete
            freeSolo
            value={selectedLogReqTypes}
            disableCloseOnSelect
            loading={loadingCreateLogRequirementType}
            isOptionEqualToValue={(option, value) => (option.name || '').toLowerCase() === (value.name || '').toLowerCase()}
            disabled={requestInProgress}
            renderTags={() => { return null; }}
            renderOption={(props, option, { selected })=>{
              const isNew = !closeoutLogState.logRequirementTypes.some(type => type.name.localeCompare(option.name, undefined, { numeric: true, sensitivity: 'base' }) === 0)
              if (isNew) {
                return (
                  <li {...props}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}>
                      <AddCircle fontSize='small'/>
                      {`Add new type: "${option.name}"`}
                    </Box>
                  </li>
                )
              }
              else {
                return (
                  <li {...props}>
                    <ExtractsCheckbox
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '1.2rem',
                        },
                      }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value if not blank and no exact match
              const isExisting = options.some((option) => inputValue.localeCompare(option.name, undefined, { numeric: true, sensitivity: 'base' }) === 0);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  name: inputValue,
                  id: undefined,
                } as NewLogRequirementType);
              }

              return filtered;
            }}
            onChange={(evt, values, reason) => {
              switch (reason) {
                case 'clear':{
                  setSelectedLogReqTypes([]);
                  break;
                }

                case 'createOption':
                case 'removeOption':
                case 'selectOption': {
                  const newValues = values.reduce((acc, value) => {
                    if (typeof value === 'string') {
                      createAndSelectLogRequirementType(value);
                    } else if (value.id === undefined) {
                      createAndSelectLogRequirementType(value.name);
                    } else {
                      acc.push(value);
                    }

                    return acc;
                  }, []);

                  setSelectedLogReqTypes(newValues);
                  break;
                }

                default:
                  break;
              }
            }}
            clearOnBlur
            handleHomeEndKeys
            multiple={true}
            ListboxProps={{
              sx: {
                maxHeight: '200px',
              },
            }}
            renderInput={(params) => {
              const count = selectedLogReqTypes.length;

              const label = (count > 0) ? `Type (${count})` : 'Type';

              return (
                <TextField
                  {...params}
                  label={label}
                  required
                  autoFocus
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingCreateLogRequirementType ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }} />
              );
            }}
            getOptionLabel={(option) => {
              return (option as NewLogRequirementType).name;
            }}
            options={closeoutLogState.logRequirementTypes}
          />
          <Autocomplete
            disabled={requestInProgress}
            value={selectedLogReqContractors}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) =>
              option.procoreServerId === value.procoreServerId
            }
            renderTags={() => { return null; }}
            onChange={(evt, value, reason) => {
              switch (reason) {
                case 'clear':
                  setSelectedLogReqContractors([]);
                  break;
                case 'removeOption':
                case 'selectOption':
                  setSelectedLogReqContractors(value);
                  break;

                default:
                  break;
              }
            }}
            inputValue={responsibleContractorInputValue}
            clearOnBlur
            onBlur={() => {
              setResponsibleContractorInputValue('');
            }}
            multiple={true}
            renderInput={(params) => {
              const count = selectedLogReqContractors.length;

              const label = (count > 0) ? `Responsible Contractor (${count})` : 'Responsible Contractor';

              return (
                <TextField
                  {...params}
                  onChange={(event) => {
                    setResponsibleContractorInputValue(event.currentTarget.value);
                  }}
                  label={label}
                />
              );
            }}
            getOptionLabel={(option) => {
              return (option as ResponsibleContractor).name;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <ExtractsCheckbox
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '1.2rem',
                    },
                  }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            options={closeoutLogState.responsibleContractors}
          />
          <Autocomplete
            disabled={requestInProgress}
            clearOnBlur
            disableCloseOnSelect
            groupBy={(option) => option.divisionName}
            inputValue={specSectionInputValue}
            value={selectedLogReqSpecSections}
            onChange={(evt, newValue, reason) => {
              switch (reason) {
                case 'clear': {
                  setSelectedLogReqSpecSections([]);
                  break;
                }

                case 'removeOption':
                case 'selectOption': {
                  setSelectedLogReqSpecSections(newValue);
                  break;
                }

                default:
                  break;
              }
            }}
            renderInput={(params) => {
              const count = selectedLogReqSpecSections.length;

              const label = (count > 0) ? `Spec Section (${count})` : 'Spec Section';

              return (
                <TextField
                  {...params}
                  label={label}
                  onChange={(event) => {
                    setSpecSectionInputValue(event.currentTarget.value);
                  }}
                />
              );
            }}
            renderTags={() => { return null; }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <ExtractsCheckbox
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: '1.2rem',
                    },
                  }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            onBlur={() => {
              setSpecSectionInputValue('');
            }}
            multiple={true}
            getOptionLabel={(option) => option.name}
            options={[...closeoutLogState.specSections].sort((a, b) => (a.divisionName || '').localeCompare((b.divisionName || ''), undefined, { numeric: true }))}
          />
          <TextField
            disabled={requestInProgress}
            size="medium"
            multiline
            rows={8}
            autoComplete="off"
            label="Description"
            value={logRequirementDescription}
            onChange={(event) =>
              setLogRequirementDescription(event.currentTarget.value)
            }
          />

          <LoadingButton
            size="large"
            buttonborderstyle="pill"
            variant="contained"
            disabled={selectedLogReqTypes.length === 0}
            onClick={handleOnAddItemsClick}
            loading={requestInProgress}
          >
            <span>
              {!requestInProgress && getAddButtonText(
                selectedLogReqTypes.length * (selectedLogReqContractors.length > 0 ? selectedLogReqContractors.length : 1) * (selectedLogReqSpecSections.length > 0 ? selectedLogReqSpecSections.length : 1)
              )}
            </span>
          </LoadingButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewRequirementModal;
