import { PackageResourceSync } from "../graphql/mutations/CreatePackageResourceSync";
import { SelectedItem } from "../graphql/mutations/UpdatePackage";
import {  BaseProcoreType, ExportOption, Package, PackageExportOption, ProcoreProjectUser, ProcoreTool } from "../types";
import { ActionPlanExtractOptions, ProcoreActionPlan } from "../types/action_plan";
import { BidExtractOptions, ProcoreBid } from "../types/bid";
import { ChangeEventExtractOptions, ProcoreChangeEvent } from "../types/change_event";
import { ProcoreCorrespondence } from "../types/correspondence";
import { DailyLogExtractOptions, ProcoreDailyLog } from "../types/daily_log";
import { DocumentExtractOptions, ProcoreDocument } from "../types/document";
import { DrawingExtractOptions, ProcoreDrawing } from "../types/drawing";
import { EmailExtractOptions, ProcoreEmail } from "../types/email";
import { FormExtractOptions, ProcoreForm } from "../types/form";
import { IncidentExtractOptions, ProcoreIncident } from "../types/incident";
import { InspectionExtractOptions, ProcoreInspection } from "../types/inspection";
import { ManagedEquipmentExtractOptions, ProcoreManagedEquipment } from "../types/managed_equipment";
import { MeetingExtractOptions, ProcoreMeeting } from "../types/meeting";
import { ObservationExtractOptions, ProcoreObservation } from "../types/observation";
import { ProcorePhoto } from "../types/photo";
import { ProjectUserExtractOptions } from "../types/project_user";
import { ProcoreProjectVendor, ProjectVendorExtractOptions } from "../types/project_vendor";
import { ProcorePunchItem, PunchItemExtractOptions } from "../types/punch_item";
import { ProcoreRfi, RfiExtractOptions } from "../types/rfi";
import { ProcoreSpecification, SpecificationExtractOption } from "../types/specification";
import { Submittal, SubmittalExtractOptions, ProcoreToolGroupings, DateRangeFilterValue, SelectFilterValue, SubmittalResponse } from "../types/submittal";
import { booleanValueOrFallback, getExtractOptionsForToolEngineName, isEmpty, sortProcoreItemsByFormattedTitle, TOOL_ENGINE_NAMES, transformSelectedItemsFromSelectedItems, transformSelectedItemsFromServerIds } from "../utils/utils";

export type NewPackageExtractOptions = {
  include_table_of_contents: boolean,
  include_project_photo_on_pdf_header: boolean,
  include_description_in_table_of_contents: boolean;
  external_storage_options?: {
    document_storage_system: string,
    storage_parent_id?: number
  };
  encrypt_pdfs: boolean;
}

export type ProcoreItem =
  Submittal |
  ProcoreRfi |
  ProcoreInspection |
  ProcoreObservation |
  ProcorePhoto |
  ProcorePunchItem |
  ProcoreDrawing |
  ProcoreDocument |
  ProcoreActionPlan |
  ProcoreSpecification |
  ProcoreIncident |
  ProcoreCorrespondence |
  ProcoreEmail |
  ProcoreForm |
  ProcoreProjectVendor |
  ProcoreMeeting |
  ProcoreDailyLog |
  ProcoreChangeEvent |
  ProcoreBid |
  ProcoreManagedEquipment |
  ProcoreProjectUser |
  BaseProcoreType
  ;

export type ProcoreToolFilter = {
  id: string;
  label: string;
  type: string;
  dataType: string;
  multiple: boolean;
  values?: SelectFilterValue[],
  value?: DateRangeFilterValue,
  key: string;
}

export type ExtractOptions =
  SubmittalExtractOptions |
  RfiExtractOptions |
  InspectionExtractOptions |
  PunchItemExtractOptions |
  DrawingExtractOptions |
  DocumentExtractOptions |
  ActionPlanExtractOptions |
  SpecificationExtractOption |
  IncidentExtractOptions |
  EmailExtractOptions |
  FormExtractOptions |
  ProjectVendorExtractOptions |
  MeetingExtractOptions |
  DailyLogExtractOptions |
  ObservationExtractOptions |
  ChangeEventExtractOptions |
  BidExtractOptions |
  ManagedEquipmentExtractOptions |
  ProjectUserExtractOptions |
  null;

export type ToolTabData = {
  procoreTool: ProcoreTool | null;
  procoreItems: ProcoreItem[];
  filters: ProcoreToolFilter[];
  selectedFilters: ProcoreToolFilter[];
  searchValue: string;
  groupings: ProcoreToolGroupings[];
  filterOpenState: Record<string, boolean>,
  selectedGroupings: ProcoreToolGroupings[];
  extractOptions: ExtractOptions;
  // TODO: Maybe this should be a Record<number, SelectedItem> to ensure uniqueness
  selectedItems: Record<string, SelectedItem>;
  loading: boolean;
  loadingItems: Record<number, boolean>;
  packageResourceSync: PackageResourceSync | null;
  loadingMore: boolean;
  failedToFetchMore: boolean;
  filteredProcoreItemServerIds: number[];
  submittalResponses: SubmittalResponse[];
}

export type NewPackageState = {
  packageId: number | null;
  createdFromPackage: Package | null;
  name: string;
  toolTabs: ToolTabData[];
  procoreTools: ProcoreTool[];
  exportOptions: ExportOption[];
  packageExportOption?: PackageExportOption;
  loading: boolean;
  extractDisabled: boolean;
  options: NewPackageExtractOptions
}

export const initialToolTab: ToolTabData = {
  procoreTool: null,
  procoreItems: [],
  filters: [],
  selectedFilters: [],
  searchValue: '',
  filterOpenState: {} as Record<string, boolean>,
  groupings: [],
  selectedGroupings: [],
  extractOptions: null,
  selectedItems: {} as Record<string, SelectedItem>,
  loading: false,
  loadingItems: {} as Record<number, boolean>,
  packageResourceSync: null,
  loadingMore: false,
  failedToFetchMore: false,
  filteredProcoreItemServerIds: [],
  submittalResponses: [],
}

export const initialState: NewPackageState = {
  packageId: null,
  createdFromPackage: null,
  name: '',
  procoreTools: [],
  exportOptions: [],
  toolTabs: [initialToolTab],
  loading: false,
  extractDisabled: true,
  packageExportOption: null,
  options: {
    include_table_of_contents: true,
    include_project_photo_on_pdf_header: false,
    include_description_in_table_of_contents: false,
    external_storage_options: null,
    encrypt_pdfs: false
  }
};

export type Action =
  | { type: 'SET_PACKAGE'; package: Package }
  | { type: 'SET_NAME'; name: string }
  | { type: 'SET_PROCORE_TOOLS'; value: { procoreTools: ProcoreTool[], exportOptions: ExportOption[], packageExportOption: PackageExportOption } }
  | { type: 'APPEND_TOOL_TAB'; }
  | { type: 'SET_TOOL'; value: { toolTabIndex: number; procoreTool: ProcoreTool } }
  | { type: 'REMOVE_TOOL_TAB'; value: { toolTabIndex: number } }
  | { type: 'SET_PROCORE_ITEMS'; value: { toolTabIndex: number; procoreItems: ProcoreItem[], filteredProcoreItemServerIds: number[] } }
  | { type: 'APPEND_PROCORE_ITEMS'; value: { toolTabIndex: number; procoreItems: ProcoreItem[] } }
  | { type: 'APPEND_PROCORE_DOCUMENT_ITEMS'; value: { toolTabIndex: number; procoreDocuments: ProcoreDocument[] } }
  | { type: 'SET_TOOL_OPTIONS'; value: { toolTabIndex: number; option: ExtractOptions } }
  | { type: 'SET_FILTERS'; value: { toolTabIndex: number; filters: ProcoreToolFilter[] } }
  | { type: 'SET_SUBMITTAL_RESPONSES'; value: { toolTabIndex: number; submittalResponses: SubmittalResponse[] } }
  | { type: 'SET_GROUPINGS'; value: { toolTabIndex: number; groupings: ProcoreToolGroupings[] } }
  | { type: 'SET_TOOL_TAB_LOADING'; value: { toolTabIndex: number; loading: boolean } }
  | { type: 'SET_TOOL_TAB_LOADING_MORE'; value: { toolTabIndex: number; loadingMore: boolean } }
  | { type: 'SET_TOOL_TAB_FAILED_TO_FETCH_MORE'; value: { toolTabIndex: number; failedToFetchMore: boolean } }
  | { type: 'SET_ITEM_LOADING'; value: { toolTabIndex: number; key: number; loading: boolean } }
  | { type: 'SET_SELECTED_FILTERS'; value: { toolTabIndex: number; selectedFilters: ProcoreToolFilter[] } }
  | { type: 'SET_FILTER_OPEN_STATE'; value: { toolTabIndex: number; filterOpenState: Record<string, boolean> } }
  | { type: 'SET_SELECTED_GROUPINGS'; value: { toolTabIndex: number; selectedGroupings: ProcoreToolGroupings[] } }
  | { type: 'SET_PACKAGE_RESOURCE_SYNC'; value: { toolTabIndex: number; packageResourceSync: PackageResourceSync } }
  | { type: 'RESET_SELECTED_ITEMS'; value: { toolTabIndex: number; } }
  | { type: 'SET_SELECTED_ITEMS'; value: { toolTabIndex: number; selectedItems: Record<string, SelectedItem>; } }
  | { type: 'SET_SEARCH_VALUE'; value: { toolTabIndex: number; value: string } }
  | { type: 'SET_NEW_PACKAGE_OPTIONS'; value: { options: NewPackageExtractOptions } }

const updateToolTabData = (
  toolTabs: ToolTabData[],
  toolTabIndex: number,
  updateToolTab: (toolTabData: ToolTabData) => ToolTabData): ToolTabData[] => {
  return toolTabs.map((toolTab, index) => {
    if (toolTabIndex === index) {
      return updateToolTab(toolTab);
    } else {
      return toolTab;
    }
  });
};

const isExtractDisabled = (name: string, toolTabs: ToolTabData[]): boolean => {
  return isEmpty(name) || (toolTabs.length === 0) || (toolTabs.length === 1 && (toolTabs[0].procoreTool === null || toolTabs[0].procoreTool === undefined)) ||
    (
      (toolTabs.length > 0 && toolTabs.some(t => t.procoreTool !== null && t.packageResourceSync !== null && t.packageResourceSync.status === 'complete'))
      &&
      toolTabs.every(t => {
        return Object.values(t.selectedItems).filter(item => item.state === 'checked' || item.state === 'indeterminate').length === 0
      })
    );
}

export const newPackageReducer: React.Reducer<NewPackageState, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_PACKAGE': {
      if (action.package.createdFromPackage) {
        const createdFromPackageOptions = action.package.createdFromPackage.options;

        const maxToolId = Math.max(...action.package.createdFromPackage.packageQueries.map(packageQuery => packageQuery.procoreTool.procoreServerId));
        return {
          ...state,
          packageId: action.package.id,
          createdFromPackage: action.package.createdFromPackage,
          options: {
            include_table_of_contents: booleanValueOrFallback(createdFromPackageOptions['include_table_of_contents'], true),
            include_project_photo_on_pdf_header: booleanValueOrFallback(createdFromPackageOptions['include_project_photo_on_pdf_header'], false),
            include_description_in_table_of_contents: booleanValueOrFallback(createdFromPackageOptions['include_description_in_table_of_contents'], false)
          },
          toolTabs: action.package.createdFromPackage.packageQueries.map(packageQuery => {

            if (packageQuery.procoreTool.engineName === TOOL_ENGINE_NAMES.DIRECTORY) {
              const itemType = packageQuery.selectedItems[0]?.item_type || 'user_directory';
              const directoryType = itemType === 'user_directory' ? 'users' : 'vendors';

              return {
                ...initialToolTab,
                procoreTool: {
                  ...packageQuery.procoreTool,
                  title: directoryType === 'users' ? 'Directory (Users)' : 'Directory (Companies)',
                  procoreServerId: directoryType === 'users' ? maxToolId + 1 : maxToolId + 2,
                  directory: true,
                  directoryType: directoryType
                },
                selectedItems: transformSelectedItemsFromSelectedItems(
                  packageQuery.procoreTool.engineName,
                  packageQuery.selectedItems,
                  directoryType
                ),
                // NOTE: This will be a problem for Directory (Users), Directory (Vendors)
                // Will probably have to split the .DIRECTORY into two separate tools during this iteration so direcctoryType is correct
                extractOptions: getExtractOptionsForToolEngineName(
                  state.exportOptions,
                  packageQuery.procoreTool.engineName,
                  packageQuery.options.options,
                  directoryType
                )
              };
            } else {
              return {
                ...initialToolTab,
                procoreTool: packageQuery.procoreTool,
                selectedItems: transformSelectedItemsFromSelectedItems(
                  packageQuery.procoreTool.engineName,
                  packageQuery.selectedItems,
                  packageQuery.procoreTool.directoryType
                ),
                // NOTE: This will be a problem for Directory (Users), Directory (Vendors)
                // Will probably have to split the .DIRECTORY into two separate tools during this iteration so direcctoryType is correct
                extractOptions: getExtractOptionsForToolEngineName(
                  state.exportOptions,
                  packageQuery.procoreTool.engineName,
                  packageQuery.options.options,
                  packageQuery.procoreTool.directoryType
                )
              };
            }
          })
        }
      } else {
        return {
          ...state,
          packageId: action.package.id,
        }
      }
    }

    case 'SET_NAME': {
      return {
        ...state,
        name: action.name,
        extractDisabled: isExtractDisabled(action.name, state.toolTabs)
      };
    }

    case 'SET_PROCORE_TOOLS': {
      if (action.value.procoreTools.filter(tool => tool.engineName === TOOL_ENGINE_NAMES.DIRECTORY).length > 0) {
        const directoryTool = action.value.procoreTools.find(tool => tool.engineName === TOOL_ENGINE_NAMES.DIRECTORY);
        const maxToolId = Math.max(...action.value.procoreTools.map(tool => tool.procoreServerId));

        const directoryUsersTool: ProcoreTool = {
          id: directoryTool.id,
          title: 'Directory (Users)',
          procoreProjectId: directoryTool.procoreProjectId,
          procoreServerId: maxToolId + 1,
          engineName: directoryTool.engineName,
          directory: true,
          directoryType: 'users'
        }

        const directoryVendorsTool: ProcoreTool = {
          id: directoryTool.id,
          title: 'Directory (Vendors)',
          procoreProjectId: directoryTool.procoreProjectId,
          procoreServerId: maxToolId + 2,
          engineName: directoryTool.engineName,
          directory: true,
          directoryType: 'vendors'
        }

        return {
          ...state,
          procoreTools: [
            ...action.value.procoreTools.filter(tool => tool.engineName !== TOOL_ENGINE_NAMES.DIRECTORY),
            directoryUsersTool,
            directoryVendorsTool
          ].sort((a, b) => (a.title || '').localeCompare(b.title || '', undefined, { numeric: true })),
          exportOptions: action.value.exportOptions,
          packageExportOption: action.value.packageExportOption,
          options: {
            ...state.options,
            encrypt_pdfs: action.value.packageExportOption.encryptPdfs,
          }
        };
      } else {
        return {
          ...state,
          procoreTools: action.value.procoreTools,
          exportOptions: action.value.exportOptions,
          packageExportOption: action.value.packageExportOption,
          options: {
            ...state.options,
            encrypt_pdfs: action.value.packageExportOption.encryptPdfs,
          }
        };
      }

    }

    case 'SET_TOOL': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return {
            ...toolTabData,
            procoreTool: action.value.procoreTool,
            extractOptions: getExtractOptionsForToolEngineName(state.exportOptions, action.value.procoreTool.engineName, {}, action.value.procoreTool.directoryType),
          }
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
        extractDisabled: isExtractDisabled(state.name, newToolTabs)
      };
    }

    case 'REMOVE_TOOL_TAB': {
      let containsEmptyToolTab = false;

      const newToolTabs = state.toolTabs.filter((toolTab, index) => {
        if (toolTab.procoreTool === null && action.value.toolTabIndex !== index) {
          containsEmptyToolTab = true;
        }

        return index !== action.value.toolTabIndex;
      });

      const newNewToolTabs = !containsEmptyToolTab && newToolTabs.length === 0 ? (
        [
          ...newToolTabs,
          initialToolTab
        ]
      ) : newToolTabs;

      return {
        ...state,
        toolTabs: newNewToolTabs,
        extractDisabled: isExtractDisabled(state.name, newToolTabs)
      };
    }

    case 'SET_PROCORE_ITEMS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return {
            ...toolTabData,
            procoreItems: sortProcoreItemsByFormattedTitle([...action.value.procoreItems]),
            filteredProcoreItemServerIds: action.value.filteredProcoreItemServerIds,
            selectedItems: toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DAILY_LOG ?
              transformSelectedItemsFromServerIds(toolTabData.procoreTool.engineName, action.value.filteredProcoreItemServerIds, 'checked', toolTabData.procoreTool.directoryType)
              : toolTabData.selectedItems
          }
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
        extractDisabled: isExtractDisabled(state.name, newToolTabs)
      }
    }

    case 'APPEND_PROCORE_ITEMS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return {
            ...toolTabData,
            procoreItems: sortProcoreItemsByFormattedTitle([...toolTabData.procoreItems, ...action.value.procoreItems]),
          }
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
        extractDisabled: isExtractDisabled(state.name, newToolTabs)
      }
    }

    case 'APPEND_PROCORE_DOCUMENT_ITEMS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return {
            ...toolTabData,
            procoreItems: toolTabData.procoreItems.concat(action.value.procoreDocuments),
            selectedItems: {
              ...toolTabData.selectedItems,
              ...action.value.procoreDocuments.reduce((acc, item) => {

                const parentSelectedItem = toolTabData.selectedItems[`folder_${item.parentId}`] || {};
                const state = parentSelectedItem['state'] || 'unchecked';
                const childState = state === 'checked' ? 'checked' : 'unchecked';

                const selectedItem = toolTabData.selectedItems[`${item.documentType}_${item.procoreServerId}`] || { state: childState }

                acc[`${item.documentType}_${item.procoreServerId}`] = {
                  itemId: item.procoreServerId,
                  itemType: item.documentType,
                  state: selectedItem.state
                }
                return acc;
              }, {})
            }
          }
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
        extractDisabled: isExtractDisabled(state.name, newToolTabs)
      }
    }

    case 'SET_TOOL_OPTIONS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, extractOptions: action.value.option }
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_FILTERS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, filters: action.value.filters };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_GROUPINGS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          if (state.createdFromPackage) {
            const previousPackageQuery = state.createdFromPackage.packageQueries.find(packageQuery => {
              return packageQuery.procoreTool.id === toolTabData.procoreTool.id
            });

            const groupingAttributes = (previousPackageQuery?.groupings || []).map(g => g.attribute);

            const selectedGroupings = action.value.groupings.filter(g => {
              return groupingAttributes.includes(g.attribute)
            });

            return {
              ...toolTabData,
              groupings: action.value.groupings,
              selectedGroupings: selectedGroupings
            }
          } else {
            return { ...toolTabData, groupings: action.value.groupings };
          }
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_TOOL_TAB_LOADING': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, loading: action.value.loading };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_SUBMITTAL_RESPONSES': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, submittalResponses: action.value.submittalResponses };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_TOOL_TAB_LOADING_MORE': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, loadingMore: action.value.loadingMore };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_TOOL_TAB_FAILED_TO_FETCH_MORE': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, failedToFetchMore: action.value.failedToFetchMore };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_ITEM_LOADING': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, loadingItems: {
            ...toolTabData.loadingItems,
            [action.value.key]: action.value.loading
          } };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_SELECTED_FILTERS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return {
            ...toolTabData,
            selectedFilters: action.value.selectedFilters,
          };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_FILTER_OPEN_STATE': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, filterOpenState: action.value.filterOpenState };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_SELECTED_GROUPINGS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, selectedGroupings: action.value.selectedGroupings };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'SET_PACKAGE_RESOURCE_SYNC': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return { ...toolTabData, packageResourceSync: action.value.packageResourceSync };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
      }
    }

    case 'APPEND_TOOL_TAB': {
      if (state.toolTabs[state.toolTabs.length - 1].procoreTool === null) {
        return {
          ...state
        }
      } else {
        return {
          ...state,
          toolTabs: [...state.toolTabs, initialToolTab]
        }
      }
    }

    case 'SET_SELECTED_ITEMS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return {
            ...toolTabData,
            selectedItems: {
              ...toolTabData.selectedItems,
              ...action.value.selectedItems,
            }
          };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
        extractDisabled: isExtractDisabled(state.name, newToolTabs)
      }
    }

    case 'RESET_SELECTED_ITEMS': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          return {
            ...toolTabData,
            selectedItems: {}
          };
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs,
        extractDisabled: isExtractDisabled(state.name, newToolTabs)
      }
    }

    case 'SET_NEW_PACKAGE_OPTIONS': {
      return {
        ...state,
        options: {
          ...action.value.options
        }
      }
    }

    case 'SET_SEARCH_VALUE': {
      const newToolTabs = updateToolTabData(
        state.toolTabs,
        action.value.toolTabIndex,
        toolTabData => {
          if (action.value.value === toolTabData.searchValue) {
            return {
              ...toolTabData,
              searchValue: action.value.value
            };
          } else {
            return {
              ...toolTabData,
              searchValue: action.value.value
            };
          }
        }
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    default:
      return state;
  }
};

export type NewPackageContextState = {
  state: NewPackageState;
  dispatch: (action: Action) => void;
};