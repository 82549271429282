import { gql } from "@apollo/client";
import { PackageData } from "../../types";
import { createQueryHook } from "../GraphqlHelpers";

export interface PackageVariables {
  accountId: string;
  procoreProjectServerId: number;
}

export const GetPackagesQuery = gql`
query GetPackages($accountId: ID!, $procoreProjectServerId: ID!) {
  packages(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId) {
    id
    name
    status
    createdAt
    exportTo
    downloadExportUrl
    packageType
    exportSize
    packageQueries {
      id
      toolTitle
      selectedItemsCount
    }
  }

  closeoutLogs(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId) {
    id
    name
    logRequirementsCount
    logRequirementsCompleteCount
    createdAt
    substantialCompletionDate
  }
}
`;

export const useGetPackageQuery = createQueryHook<PackageData, PackageVariables>(GetPackagesQuery);