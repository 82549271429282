import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import { Typography } from './shared/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { Colors } from '../styles';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import Link from '@mui/material/Link';
import { useUpdateCloseoutLogDataGridView } from '../graphql/mutations/closeout/UpdateCloseoutLogDataGridView';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { CloseoutLogColumnDefinition } from '../types';
import { BlueSwitch } from './package/styles';
import Check from '@mui/icons-material/Check';

interface IUploadFilesDndModalProps {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  onClose: () => void;
  launchInput: () => void;
  onClickFileUpload: (files, loqRequirementId?: number) => void;
}

export const UploadFilesDndModal = ({
  accountId,
  procoreProjectServerId,
  closeoutLogId,
  onClose,
  launchInput,
  onClickFileUpload,
  ...rest
}: IUploadFilesDndModalProps & DialogProps): JSX.Element => {
  const { state, dispatch } = React.useContext(CloseoutLogContext);

  const [updateCloseoutLogDataGridView] = useUpdateCloseoutLogDataGridView({
    accountId: accountId,
    procoreProjectServerId: procoreProjectServerId,
    closeoutLogId: closeoutLogId,
    id: state.currentCloseoutLogDataGridView?.id,
    attributes: {
      columnDefinitions: [],
      sortModel: [],
      groupings: [],
      groupingColumnDefinition: {} as CloseoutLogColumnDefinition,
      filters: [],
      markRequirementCompleteOnFileUpload: false,
      markRequirementCompleteOnApprovedFileRequest: false,
    },
  });

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (data: DataTransfer) => {
      onClickFileUpload(Array.from(data.files || []));
      onClose();
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      disableScrollLock
      onClose={onClose}
      {...rest}
      PaperProps={{
        sx: {
          maxWidth: '550px',
          height: '580px',
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '24px 28px 32px 28px',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography typestyle="xl">Upload Files</Typography>
          <IconButton onClick={() => onClose()}>
            <Close sx={{ fontSize: '18px' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '16px 20px',
            alignItems: 'center',
            gap: '12px',
            borderRadius: '8px',
            border: `1px solid ${Colors.lightishGray}`,
            boxShadow:
              '0px 2px 4px 1px rgba(26, 32, 36, 0.04), 0px 1px 10px 0px rgba(26, 32, 36, 0.06)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              minWidth: '22px',
              minHeight: '22px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '11px',
              backgroundColor: Colors.green,
            }}
          >
            <Check sx={{ color: Colors.white, fontSize: '14px' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1', gap: '4px' }}>
            <Typography typestyle="s bold">Automatic Completion</Typography>
            <Typography typestyle="xs" color={Colors.darkishGray}>
              Set requirement status to “Complete” when files are finished
              uploading.
            </Typography>
          </Box>
          <BlueSwitch
            size={'small'}
            checked={
              state.currentCloseoutLogDataGridView?.markRequirementCompleteOnFileUpload
            }
            onChange={(evt) => {
              dispatch({
                type: 'SET_AUTO_MARK_COMPLETE_CONFIG',
                value: evt.target.checked,
              });
              updateCloseoutLogDataGridView({
                variables: {
                  accountId: accountId,
                  procoreProjectServerId: procoreProjectServerId,
                  closeoutLogId: closeoutLogId,
                  id: state.currentCloseoutLogDataGridView?.id,
                  attributes: {
                    columnDefinitions:
                      state.currentCloseoutLogDataGridView?.columnDefinitions,
                    sortModel: state.currentCloseoutLogDataGridView?.sortModel,
                    groupings: state.currentCloseoutLogDataGridView?.groupings,
                    groupingColumnDefinition:
                      state.currentCloseoutLogDataGridView
                        ?.groupingColumnDefinition,
                    filters: state.currentCloseoutLogDataGridView?.filters,
                    markRequirementCompleteOnFileUpload: evt.target.checked,
                    markRequirementCompleteOnApprovedFileRequest: state.currentCloseoutLogDataGridView?.markRequirementCompleteOnApprovedFileRequest,
                  },
                },
              })
            }}
          />
        </Box>
        <Box
          ref={drop}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            borderColor: isOver ? Colors.newAccentBlue : Colors.reallyBlueGray,
            height: '100%',
            borderStyle: 'dashed',
            borderWidth: isOver ? '1.5px' : '1px',
            backgroundColor: isOver ? Colors.blue0 : Colors.white,
            transition:
              'borderColor 0.2s, borderWidth 0.2s, backgroundColor 0.3s',
            transitionTimingFunction: 'ease-in-out',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '108px',
            }}
          >
            <img
              src="/images/file_blue.svg"
              width="81px"
              height="90px"
              style={{
                pointerEvents: 'none',
              }}
            />
            <img
              src="/images/upload_arrow_circle.svg"
              width="30px"
              height="30px"
              style={{
                position: 'relative',
                bottom: isOver ? '28px' : '24px',
                transition: 'bottom 0.3s ease-in-out',
                borderRadius: '15px',
                boxShadow: '0px 2px 6px rgba(0,0,0,0.2)',
                pointerEvents: 'none',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
              alignItems: 'center',
            }}
          >
            <Typography
              typestyle="ml"
              color={isOver ? Colors.newAccentBlue : Colors.darkerGray}
            >
              {isOver
                ? 'Drop files to start uploading'
                : 'Drag & drop files here'}
            </Typography>
            <Link
              underline="hover"
              color={isOver ? Colors.mediumDarkGray : Colors.newAccentBlue}
              onClick={launchInput}
              sx={{ cursor: 'pointer' }}
            >
              <Typography typestyle="xs">
                or click here to browse for a file
              </Typography>
            </Link>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
