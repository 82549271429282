import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { FilterOptionInput } from "../../types";
import { ProcoreRfi } from "../../types/rfi";
import { ProcoreToolGroupings } from "../../types/submittal";

export type ProcoreRfiFilterData = {
  rfiFilters: ProcoreToolFilter[];
  rfiDefaultFilters: ProcoreToolFilter[];
  rfiGroupings: ProcoreToolGroupings[];
};

export type ProcoreFilterRfiData = {
  rfis: ProcoreRfi[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreRfiVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterRfiVariables = ProcoreRfiVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const RfiFragment = gql`
fragment RfiFragment on ProcoreRfi {
  id
  procoreServerId
  formattedTitle
  responsibleContractor
  locationName
  projectStageName
}
`;

export const filterProcoreRfisQuery = gql`
query FilterProcoreRfis($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  rfis(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...RfiFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${RfiFragment}
`;

export const fetchPaginatedProcoreRfisQuery = gql`
query FilterPaginatedProcoreRfis($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  rfis(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...RfiFragment
  }
}
${RfiFragment}
`;