import React from 'react';
import { Popover, Box, PopoverProps } from '@mui/material';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { Button } from './shared/Button';
import { CloseoutFilterRow } from './CloseoutFilterRow';
import { filterFields } from './CloseoutLog';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteOutline from '@mui/icons-material/DeleteOutline';

interface ICloseoutFilterPopoverProps {
  filters: GridFilterItem[];
  onResetFilters: () => void;
  onRemoveFilter: (id: number | string) => void;
  onUpdateFilter: (newFilter: GridFilterItem, index: number) => void;
  onAppendNewFilter: (newFilter: GridFilterItem) => void;
  handleCloseModal: () => void;
}

export const CloseoutFilterPopover = ({
  filters,
  handleCloseModal,
  onResetFilters,
  onRemoveFilter,
  onUpdateFilter,
  onAppendNewFilter,
  ...props
}: PopoverProps & ICloseoutFilterPopoverProps): JSX.Element => {
  // Starts with full list of filter fields and narrows down only to fields that aren't in filters yet
  const unusedFilterFields = filters.reduce((acc, nextItem) => {
    return acc.filter((item) => item.field !== nextItem.field);
  }, filterFields);

  const handleAppendItem = () => {
    if (unusedFilterFields.length === 0) { return; }

    onAppendNewFilter({
      field: unusedFilterFields[0].field,
      operator: 'isOneOf',
      id: unusedFilterFields[0].id,
    });
  };

  return (
    <Popover {...props} onClose={handleCloseModal}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '20px 4px 8px 0px',
          width: '540px',
        }}
      >
        {filters.map((filter, i) => {
          return (
            <CloseoutFilterRow
              filters={filters}
              filterItem={filter}
              index={i}
              handleRemoveItem={() => onRemoveFilter(filter.id)}
              handleUpdateItem={(item: GridFilterItem, index: number) =>
                onUpdateFilter(item, index)
              }
              key={`filter-row-${i}`}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '4px 16px 12px 16px',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '8px',
        }}
      >
        <Button
          size="small"
          onClick={handleAppendItem}
          disabled={unusedFilterFields.length === 0}
          startIcon={
            <AddCircleOutline
              sx={{ '&.MuiSvgIcon-root': { fontSize: '16px' } }}
            />
          }
        >
          Add Filter
        </Button>
        <Button
          size="small"
          color="error"
          onClick={onResetFilters}
          startIcon={
            <DeleteOutline sx={{ '&.MuiSvgIcon-root': { fontSize: '16px' } }} />
          }
        >
          Clear All
        </Button>
      </Box>
    </Popover>
  );
};
