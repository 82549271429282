import React from 'react';
import { GridRowGroupingModel } from '@mui/x-data-grid-premium';
import {
  Popover,
  MenuItem,
  styled,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import SegmentOutlined from '@mui/icons-material/SegmentOutlined';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Typography } from './Typography';
import { Button } from './Button';

const CheckedIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: '18px',
    width: '18px',
    background: '#007BFF',
    color: 'white',
    borderRadius: '2px',
    marginRight: '8px',
    padding: 0,
    '&:hover': {
      background: '#007BFF',
    },
  };
});

const UncheckedIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: '18px',
    width: '18px',
    background: 'white',
    border: '2px solid #CED4DA',
    marginRight: '8px',
    borderRadius: '2px',
    padding: 0,
    '&:hover': {
      background: 'white',
    },
  };
});

export type GroupMenuOption = {
  field: string;
  headerName: string;
}

export interface IGroupSelectProps {
  groupMenuOptions: GroupMenuOption[];
  gridRowGroupingModel: GridRowGroupingModel;
  setGridRowGroupingModel: (selectedGroupings: string[]) => void;
  disabled: boolean;
}

export const GroupingSelect = ({
  groupMenuOptions,
  gridRowGroupingModel,
  setGridRowGroupingModel,
  disabled,
}: IGroupSelectProps) => {
  const handleOnClick = (newValue: GridRowGroupingModel, exists: boolean) => {
    if (!exists) {
      setGridRowGroupingModel(gridRowGroupingModel.concat(newValue));
    } else {
      setGridRowGroupingModel(
        gridRowGroupingModel.filter((val) => val !== newValue.toString())
      );
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>(null);

  return (
    <>
      <Button
        variant="outlined"
        buttonborderstyle="pill"
        disabled={disabled}
        onClick={(evt: React.MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(evt.currentTarget)
        }
        startIcon= {<SegmentOutlined fontSize="small"/>}
        endIcon= {<ArrowDropDown fontSize="small"/>}
      >
        {gridRowGroupingModel.length === 0
          ? 'Group'
          : `Group (${gridRowGroupingModel.length})`}
      </Button>
      <Popover
        PaperProps={{ sx: { padding: '12px 0px' } }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {groupMenuOptions.map((groupMenuOption) => {
          const indexOf = gridRowGroupingModel.indexOf(groupMenuOption.field);
          const exists = indexOf !== -1;

          if (exists) {
            return (
              <MenuItem
                onClick={() =>
                  handleOnClick(
                    [groupMenuOption.field],
                    exists
                  )
                }
                key={`grouping-menu-item-${groupMenuOption.field}`}
                value={groupMenuOption.field}
              >
                <CheckedIconButton
                  key={`grouping-icon-button-${groupMenuOption.field}`}
                  disableFocusRipple={true}
                  disableRipple={true}
                >
                  <Typography variant="body2">{indexOf + 1}</Typography>
                </CheckedIconButton>
                <Typography>{groupMenuOption.headerName}</Typography>
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                onClick={() =>
                  handleOnClick(
                    [groupMenuOption.field],
                    exists
                  )
                }
                key={`grouping-menu-item-${groupMenuOption.field}`}
                value={groupMenuOption.field}
              >
                <UncheckedIconButton
                  key={`grouping-icon-button-${groupMenuOption.field}`}
                  disableFocusRipple={true}
                  disableRipple={true}
                ></UncheckedIconButton>
                <Typography>{groupMenuOption.headerName}</Typography>
              </MenuItem>
            );
          }
        })}
      </Popover>
    </>
  );
};
