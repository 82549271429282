import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { CloseoutLogColumnDefinition, CloseoutLogDataGridView } from "../../../types";
import { GridFilterItem } from "@mui/x-data-grid-premium";

export interface CloseoutLogDataGridViewUpdateData {
  updateCloseoutLogDataGridView: {
    success: boolean;
    closeoutLogDataGridView: CloseoutLogDataGridView;
  }
}

interface CloseoutLogDataGridViewUpdateAttributes {
  columnDefinitions: CloseoutLogColumnDefinition[];
  sortModel: { field: string; sort: string; }[];
  groupings: string[];
  groupingColumnDefinition: CloseoutLogColumnDefinition;
  filters: GridFilterItem[];
  markRequirementCompleteOnFileUpload: boolean;
  markRequirementCompleteOnApprovedFileRequest: boolean;
}

export interface CloseoutLogDataGridViewUpdateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  id: number;
  attributes: CloseoutLogDataGridViewUpdateAttributes;
}

export const useUpdateCloseoutLogDataGridView = createMutationHook<
CloseoutLogDataGridViewUpdateData,
CloseoutLogDataGridViewUpdateVariables
>(gql`
  mutation UpdateCloseoutLogDataGridView(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $id: ID!,
    $attributes: CloseoutLogDataGridViewUpdateAttributes!
  ) {
    updateCloseoutLogDataGridView(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, id: $id, attributes: $attributes }
    ) {
      success
      closeoutLogDataGridView {
        id
        name
        personal
        columnDefinitions
        sortModel
        groupingColumnDefinition
        groupings
        filters
        markRequirementCompleteOnFileUpload
        markRequirementCompleteOnApprovedFileRequest
      }
    }
  }
`);
