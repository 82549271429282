import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { ProcoreToolGroupings } from "../../types/submittal";
import { createLazyQueryHook } from "../GraphqlHelpers";

export type ProcoreFilterData = {
  filters: ProcoreToolFilter[];
  defaultFilters: ProcoreToolFilter[];
  groupings: ProcoreToolGroupings[];
};

export type ProcoreFilterVariables = {
  accountId: string;
  procoreProjectServerId: number;
  procoreToolId: number;
  directoryType?: string;
};

export const getProcoreToolFiltersQuery = gql`
query GetProcoreToolFilters($accountId: ID!, $procoreProjectServerId: ID!, $procoreToolId: ID!, $directoryType: String) {
  filters(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, procoreToolId: $procoreToolId, directoryType: $directoryType) {
    id
    label
    type
    multiple
    dataType
    values {
      id
      key
      value
    }
    value {
      startDate
      endDate
    }
    key
  }

  defaultFilters(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, procoreToolId: $procoreToolId, directoryType: $directoryType) {
    id
    label
    type
    multiple
    dataType
    values {
      id
      key
      value
    }
    value {
      startDate
      endDate
    }
    key
  }

  groupings(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, procoreToolId: $procoreToolId, directoryType: $directoryType) {
    id
    label
    attribute
    attributeSource
    defaultValue
    groupingLabel
    operator
    conditional
  }
}
`;

export const useGetToolFilters = createLazyQueryHook<ProcoreFilterData, ProcoreFilterVariables>(getProcoreToolFiltersQuery);