import React from 'react';
import { ProcoreToolGroupings } from '../../types/submittal';
import { Box, IconButton } from '@mui/material';
import { Typography } from './Typography';
import { Colors } from '../../styles';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import Close from '@mui/icons-material/Close';
import AddCircle from '@mui/icons-material/AddCircle';
import { Button } from './Button';

export interface IAutomationsGroupBy {
  selectedGroupings: ProcoreToolGroupings[];
  setSelectedGroupings: (selectedGroupings: ProcoreToolGroupings[]) => void;
  editSelectedGrouping: (selectedGrouping: number) => void;
  onClickAddToPath: () => void;
}

export const AutomationsGroupBy = ({
  selectedGroupings,
  setSelectedGroupings,
  editSelectedGrouping,
  onClickAddToPath,
}: IAutomationsGroupBy) => {
  const [hoverState, setHoverState] = React.useState<Record<string, boolean>>({});

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="8px" flexWrap={'wrap'}>
      {
        selectedGroupings.map((grouping, index) => {
          return (
            <Box key={`selected-groupings-${index}`}
              display="flex"
              flexDirection="row"
              alignItems="center"
              onBlur={() => setHoverState({ ...hoverState, [grouping.id]: false })}
              onMouseEnter={() => setHoverState({ ...hoverState, [grouping.id]: true })}
              onMouseLeave={() => setHoverState({ ...hoverState, [grouping.id]: false })}
              gap="8px">
              {
                (hoverState[grouping.id] || false) ? (
                  <IconButton sx={{padding: '2px'}} size='small' disableFocusRipple disableRipple disableTouchRipple onClick={() => {
                    setSelectedGroupings(selectedGroupings.filter(g => g.id !== grouping.id));
                    setHoverState({ ...hoverState, [grouping.id]: false });
                  }}>
                    <Close sx={{fontSize: '14px'}}/>
                  </IconButton>
                ) : <FolderOpenOutlinedIcon fontSize='small' />
              }
              <Typography typestyle='m' onClick={() => {
                editSelectedGrouping(index);
              }} sx={{
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                },
              }}>{grouping.label}</Typography>
              <Typography typestyle='m' color={Colors.mediumGray}>&nbsp;/&nbsp;</Typography>
            </Box>
          );
        })
      }
      <Button variant="outlined" startIcon={<AddCircle />} size="small" onClick={() => {
        onClickAddToPath();
      }}>
        Add to Path
      </Button>
    </Box>
  );
};
