import React, { useState } from "react";
import { ProcoreToolFilter } from "../../../contexts/NewPackageContext";
import { SelectFilterValue, isSelectFilter } from "../../../types/submittal";
import Box from "@mui/material/Box";
import { Button } from "../Button";
import Divider from "@mui/material/Divider";
import { Search, SearchCloseIcon, SearchCloseIconButton, SearchIconWrapper, StyledInputBase } from "../../package/styles";
import SearchIcon from '@mui/icons-material/Search';
import { FilterDateRangeSelect } from "../FilterDateRangeSelect";
import { FilterAutocomplete } from "../FilterAutocomplete";
import { AddFilterAutocomplete } from "../AddFilterAutocomplete";
import { formatISO } from "date-fns";

export interface FilterOptionsProps {
  disabled: boolean;
  searchValue: string;
  allItemsAreSelected: boolean;
  filters: ProcoreToolFilter[];
  selectedFilters: ProcoreToolFilter[];
  filterOpenState: Record<string, boolean>
  onSearchValueChanged: (value: string) => void;
  onSearchValueEntered: (value?: string) => void;
  onFilterOptionSelectionChange: ({ selectedFilters }: { selectedFilters?: ProcoreToolFilter[] }) => void;
  onSelectAllButtonClicked: () => void;
  onFilterOpenStateChanged: (filterOpenState: Record<string, boolean>) => void;
  onSelectedFiltersChanged: (selectedFilters: ProcoreToolFilter[]) => void;
  popperContainer?: Element;
}

const FilterActionBar = ({
  disabled,
  searchValue,
  allItemsAreSelected,
  selectedFilters,
  filters,
  filterOpenState,
  onSearchValueChanged,
  onSearchValueEntered,
  onFilterOptionSelectionChange,
  onSelectAllButtonClicked,
  onFilterOpenStateChanged,
  onSelectedFiltersChanged,
  popperContainer
}: FilterOptionsProps) => {
  const [focusSearch, setFocusSearch] = useState(false);
  const inputBaseRef = React.useRef(null);
  const [selectedFilterLabels, setSelectedFilterLabels] = useState<string[]>(
    selectedFilters.map((f) => f.label));

    const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    Record<string, string[]>
  >(
    selectedFilters.reduce((acc, current) => {
      if (isSelectFilter(current)) {
        acc[current.label] = current.values.map((v) => v.value);
        return acc;
      } else {
        const values = [];

        if (current?.value?.startDate) {
          values.push(formatISO(current.value.startDate));
        }

        if (current?.value?.endDate) {
          values.push(formatISO(current.value.endDate));
        }
        acc[current.label] = values;
        return acc;
      }
    }, {})
  );

  const dispatchSetSelectedFilters = (
    selectedFilterLabels: string[],
    selectedFilterOptions: Record<string, string[]>,
    triggerFilterOptionSelectionChange = false
  ) => {
    const newSelectedFilters: ProcoreToolFilter[] = selectedFilterLabels.map(
      (selectedFilter) => {
        const filter: ProcoreToolFilter = filters.find(
          (f) => f.label === selectedFilter
        );

        if (isSelectFilter(filter)) {
          return {
            ...filter,
            values: filter.values.filter((v) =>
              (selectedFilterOptions[filter.label] || []).includes(v.value)
            ),
          };
        } else {
          const [startDate, endDate] =
            selectedFilterOptions[filter.label] || [];
          return {
            ...filter,
            value: {
              startDate: startDate ? new Date(startDate) : undefined,
              endDate: endDate ? new Date(endDate) : undefined,
            },
          };
        }
      }
    );

    onSelectedFiltersChanged(newSelectedFilters);

    if (triggerFilterOptionSelectionChange) {
      onFilterOptionSelectionChange({ selectedFilters: newSelectedFilters });
    }
  };

  const handleAddFilterSelectChanged = (
    newFilters: string[],
    triggerFilterOptionSelectionChange = false
  ) => {
    const selectedFilterLength = selectedFilterLabels.length;

    // If there are new filters then we can close the AddFilterSelect
    // set all other existings filters open to false and the new 1 to true
    // We'll have to manually manage open/close state for each filter.
    if (newFilters.length > selectedFilterLength) {
      const newFilterOpenState = newFilters.reduce((acc, filterLabel) => {
        const alreadyExists = selectedFilterLabels.includes(filterLabel);
        acc[filterLabel] = !alreadyExists;

        return acc;
      }, {} as Record<string, boolean>);

      onFilterOpenStateChanged(newFilterOpenState);
    } else {
      const newFilterOpenState = newFilters.reduce((acc, filterLabel) => {
        acc[filterLabel] = false;
        return acc;
      }, {} as Record<string, boolean>);


      onFilterOpenStateChanged(newFilterOpenState);
    }

    setSelectedFilterLabels(newFilters);
    const newSelectedFilterOptions = newFilters.reduce((acc, filterLabel) => {
      acc[filterLabel] = selectedFilterOptions[filterLabel] || [];

      return acc;
    }, {} as Record<string, string[]>);

    setSelectedFilterOptions(newSelectedFilterOptions);

    dispatchSetSelectedFilters(
      newFilters,
      newSelectedFilterOptions,
      triggerFilterOptionSelectionChange
    );
  };

  const handleSelectedFilterOptionsChanged = (
    values: SelectFilterValue[],
    filterLabel: string
  ) => {
    const state = {
      ...selectedFilterOptions,
      [filterLabel]: values.map(v => v.value),
    };

    setSelectedFilterOptions(state);

    dispatchSetSelectedFilters(selectedFilterLabels, state);
  };

  const onFilterClose = (filterLabel: string) => {
    onFilterOpenStateChanged({
      ...filterOpenState,
      [filterLabel]: false
    })

    onFilterOptionSelectionChange({ selectedFilters: undefined });
  };

  const onFilterOpen = (filterLabel: string) => {
    onFilterOpenStateChanged({
      ...filterOpenState,
      [filterLabel]: true
    })
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexWrap="wrap"
      margin={1}
      style={{
        gap: '4px'
      }}
    >
      <Button
        disabled={disabled}
        onClick={() => onSelectAllButtonClicked()}
        variant="outlined"
        disableElevation={true}
        size="medium"
        style={{
          minHeight: '39px',
          marginRight: "12px"
        }}
      >
        {allItemsAreSelected ? "Deselect Filtered" : "Select Filtered"}
      </Button>
      <Divider orientation="vertical" style={{ height: '39px'}} />
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          isopen={(focusSearch || Boolean((searchValue || '').trim())).toString()}
          inputRef={inputBaseRef}
          fullWidth={true}
          placeholder="Search..."
          value={searchValue}
          onKeyDown={(evt) => {
            if (evt.key.toLowerCase() === "enter") {
              inputBaseRef?.current?.blur();
              onSearchValueEntered()
            }
          }}
          endAdornment={
            (((searchValue || '').trim())) ? (
              <SearchCloseIconButton
                  size={'small'}
                  onClick={() => {
                    onSearchValueChanged('');
                    onSearchValueEntered('');
                  }}
                  style={{ visibility: ((searchValue || '').trim()) ? 'visible' : 'hidden' }}>
                <SearchCloseIcon />
              </SearchCloseIconButton>
            ) : null
          }
          onChange={(evt) => {
            onSearchValueChanged(evt.target.value)
          }}
          onFocus={() => { setFocusSearch(true)}}
          onBlur={() => {
            setFocusSearch(false);
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>

      <AddFilterAutocomplete
        options={filters.filter((f) => f?.values?.length > 0 || f.type === "daterange").map(f => f.label)}
        selectedOptions={selectedFilterLabels}
        key={`add-filter-autocomplete`}
        onClose={() => { /** Do Nothing */ }}
        onChange={(values) => {
          handleAddFilterSelectChanged(values, false)
        }}
        popperContainer={popperContainer}
        filterLabel={"Add Filters"}
      />

      {selectedFilterLabels.map((selectedFilter) => {
        const filter = filters.filter((f) => f.label === selectedFilter)[0];

        switch (filter.type) {
          case "select": {
            if (filter.multiple == false) {
              return (
                <FilterAutocomplete
                  disabled={false}
                  key={`select-single-${filter.label}`}
                  filterLabel={filter.label}
                  open={filterOpenState[filter.label] || false}
                  onOpen={() => onFilterOpen(filter.label)}
                  popperContainer={popperContainer}
                  onClose={() => {
                    onFilterOpenStateChanged({
                      ...filterOpenState,
                      [filter.label]: false
                    })
                  }}
                  removeFilter={() => {
                    handleAddFilterSelectChanged(
                      selectedFilterLabels.filter((f) => f !== filter.label),
                      true
                    );
                  }}
                  options={filter.values || []}
                  selectedOptions={selectedFilters.find(f => f.label === filter.label)?.values || []}
                  onChange={(values) => {
                    const state = {
                      ...selectedFilterOptions,
                      [filter.label]: values.map(v => v.value),
                    };
                    setSelectedFilterOptions(state);

                    onFilterOpenStateChanged({
                      ...filterOpenState,
                      [filter.label]: false
                    })

                    dispatchSetSelectedFilters(selectedFilterLabels, state, true);
                  }}
                />
              );
            } else {
              return (
                <FilterAutocomplete
                  disabled={false}
                  popperContainer={popperContainer}
                  key={`select-multiple-${filter.label}`}
                  filterLabel={filter.label}
                  open={filterOpenState[filter.label] || false}
                  onOpen={() => onFilterOpen(filter.label)}
                  onChange={(values) => {
                    handleSelectedFilterOptionsChanged(values, filter.label)
                  }}
                  selectedOptions={selectedFilters.find(f => f.label === filter.label)?.values || []}
                  onClose={() => onFilterClose(filter.label)}
                  options={filter.values || []}
                  removeFilter={() =>
                    handleAddFilterSelectChanged(
                      selectedFilterLabels.filter((f) => f !== filter.label),
                      true
                    )
                  }
                />
              );
            }
          }

          case "daterange": {
            const [startDate, endDate] =
              selectedFilterOptions[filter.label] || [];
            const initialDateRange = {
              startDate: startDate ? new Date(startDate) : undefined,
              endDate: endDate ? new Date(endDate) : undefined,
            };
            return (
              <FilterDateRangeSelect
                popperContainer={popperContainer}
                key={`date-range-select-${filter.label}`}
                filterLabel={filter.label}
                open={filterOpenState[filter.label] || false}
                onOpen={() => onFilterOpen(filter.label)}
                onClose={() => onFilterClose(filter.label)}
                initialDateRange={initialDateRange}
                onChange={(values) => {
                  const state = {
                    ...selectedFilterOptions,
                    [filter.label]: values,
                  };

                  setSelectedFilterOptions(state);

                  dispatchSetSelectedFilters(selectedFilterLabels, state);
                }}
                removeFilter={() =>
                  handleAddFilterSelectChanged(
                    selectedFilterLabels.filter((f) => f !== filter.label),
                    true
                  )
                }
              />
            );
          }

          default:
            throw new Error("Unsupported type.");
        }
      })}
    </Box>
  );
};

export default FilterActionBar;