import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreActionPlan } from "../../types/action_plan";

export type ProcoreFilterActionPlanData = {
  actionPlans: ProcoreActionPlan[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreActionPlanVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterActionPlanVariables = ProcoreActionPlanVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const ActionPlanFragment = gql`
fragment ActionPlanFragment on ProcoreActionPlan {
  id
  formattedTitle
  procoreServerId
  locationName
  managerName
  planTypeName
  status
  description
}
`;

export const filterProcoreActionPlansQuery = gql`
query FilterProcoreActionPlans($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  actionPlans(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ActionPlanFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${ActionPlanFragment}
`;

export const fetchPaginatedProcoreActionPlansQuery = gql`
query FilterPaginatedProcoreActionPlans($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  actionPlans(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ActionPlanFragment
  }
}
${ActionPlanFragment}
`;