import { ApolloError } from "@apollo/client";
import { ExtractOptions, NewPackageState, ProcoreItem, ProcoreToolFilter, ToolTabData } from "../contexts/NewPackageContext";
import { SelectedItem } from "../graphql/mutations/UpdatePackage";
import { FilterOptionInput, SelectFilterValueInput, GroupingsInput, DateRangeFilterValueInput, User, ProcoreTool, Account, Role, LogRequirement, SpecSection, ResponsibleContractor, NewLogRequirementType, ExportOption, DirectoryType } from "../types";
import { RecordAttachment } from "../types/record_attachment";
import { isSelectFilter, ProcoreToolGroupings, SelectFilterValue } from "../types/submittal";
import { Attribute, CheckState } from "./multi-tier-grouping";
import { Page, Breadcrumb } from "../components/shared/BreadcrumbNav";
import { LogRequirementCreateAttributes } from "../graphql/mutations/closeout/CreateLogRequirements";
import { formatISO, parse } from "date-fns";
import { CloseoutTab } from "../contexts/CloseoutLogContext";
import { GridCellParams, GridColDef, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-premium";

export const TOOL_ENGINE_NAMES = {
  ACTION_PLANS: 'itp',
  CORRESPONDENCE: 'communication_types',
  DIRECTORY: 'directory',
  DOCUMENTS: 'documents',
  DRAWINGS: 'drawing_log',
  EMAILS: 'communication',
  FORMS: 'forms',
  INCIDENTS: 'incidents',
  INSPECTIONS: 'checklists',
  OBSERVATIONS: 'observations',
  PHOTOS: 'images',
  PUNCH_LIST:'punch_list',
  RFIS: 'rfi',
  SPECIFICATIONS: 'specification_sections',
  SUBMITTALS: 'submittal_log',
  MEETINGS: 'meetings',
  DAILY_LOG: 'daily_log',
  CHANGE_EVENTS: 'change_events',
  BIDDING: 'bidding',
  EQUIPMENT: 'managed_equipment',
}


export const initMaintenanceBanner = () => {
  try {
    const result = window.localStorage.getItem('show_ep_maintenance_banner');

    if (result === null) {
      window.localStorage.setItem('show_ep_maintenance_banner', 'true');
      return true;
    } else {
      return result === 'true';
    }
  } catch {
    return true;
  }
}

export const hideMaintenanceBanner = () => {
  try {
    window.localStorage.setItem('show_ep_maintenance_banner', 'false');
  } catch {
    console.error('Failed to set show_ep_maintenance_banner to false');
  }
}

export const showDescriptionFor = (engineName: string): boolean => {
  switch (engineName) {
    case TOOL_ENGINE_NAMES.RFIS:
      return false;
    case TOOL_ENGINE_NAMES.SUBMITTALS:
      return true;
    case TOOL_ENGINE_NAMES.INSPECTIONS:
      return true;
    case TOOL_ENGINE_NAMES.OBSERVATIONS:
      return true;
    case TOOL_ENGINE_NAMES.INCIDENTS:
      return true;
    case TOOL_ENGINE_NAMES.CORRESPONDENCE:
      return false;
    case TOOL_ENGINE_NAMES.PHOTOS:
      return false;
    case TOOL_ENGINE_NAMES.PUNCH_LIST:
      return false;
    case TOOL_ENGINE_NAMES.DRAWINGS:
      return false;
    case TOOL_ENGINE_NAMES.ACTION_PLANS:
      return true;
    case TOOL_ENGINE_NAMES.SPECIFICATIONS:
      return false;
    case TOOL_ENGINE_NAMES.FORMS:
      return true;
    case TOOL_ENGINE_NAMES.EMAILS:
      return false;
    case TOOL_ENGINE_NAMES.DIRECTORY:
      return false;
    case TOOL_ENGINE_NAMES.MEETINGS:
      return true;
    case TOOL_ENGINE_NAMES.DAILY_LOG:
      return false;
    case TOOL_ENGINE_NAMES.CHANGE_EVENTS:
      return false;
    default:
      return false;
  }
}

export const parseLocationPathname = (pathname: string): {
  accountId?: string,
  procoreProjectServerId?: number,
  closeoutLogId?: number,
} => {
  const regex = /\/teams\/([^/]+)\/projects\/([^/]+)(?:\/closeout_logs\/([^/]+))?/;
  const match = pathname.match(regex);

  if (match) {
    return {
      accountId: match[1],
      procoreProjectServerId: match[2] ? parseInt(match[2]) :undefined,
      closeoutLogId: match[3] ? parseInt(match[3]) : undefined,
    }
  } else {
    return {};
  }
}

export const downloadToCsv = async (props: {
  accountId: string,
  procoreProjectServerId: number,
  closeoutLogId: number,
  logType: 'requirements' | 'file_requests',
  ids: string[] | number[] | 'all'
}): Promise<void> => {
  const { accountId, procoreProjectServerId, closeoutLogId, logType, ids } = props;

  const csvDownloadUrl = buildCsvDownloadUrl(accountId, procoreProjectServerId, closeoutLogId, logType);

  const response = await fetch(csvDownloadUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "X-CSRF-Token": getCSRFToken()
    },
    body: ids === 'all' ? JSON.stringify({}) : JSON.stringify({
      ids: ids
    }),
  });

  if (!response.ok) {
    throw new Error(`downloadToCsv for logType: ${logType} failed with status: ${response.status}`);
  }

  // Handling the response as a Blob
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', csvFilename(logType)); // Any filename you wish to assign
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const csvFilename = (logType: 'requirements' | 'file_requests'): string => {
  if (logType === 'requirements') {
    return 'closeout_requirements_log.csv';
  } else {
    return 'closeout_file_requests_log.csv';
  }
};

export const buildCsvDownloadUrl = (
  accountId: string,
  procoreProjectServerId: number,
  closeoutLogId: number,
  logType: 'requirements' | 'file_requests'): string => {
    const baseUrl = `${window.location.origin}/teams/${accountId}/projects/${procoreProjectServerId}/closeout_logs/${closeoutLogId}`;

    if (logType === 'requirements') {
      return `${baseUrl}/export_log_requirements_to_csv`;
    } else {
      return `${baseUrl}/export_file_requests_to_csv`;
    }
  }

export function getCSRFToken(): string {
  const el = document.querySelector('meta[name="csrf-token"]');
  return (el && el.getAttribute('content')) || '';
}

export const CLOSEOUT_TOOL_ORDER: string[] = [
  TOOL_ENGINE_NAMES.DOCUMENTS,
  TOOL_ENGINE_NAMES.DRAWINGS,
  TOOL_ENGINE_NAMES.PHOTOS,
  TOOL_ENGINE_NAMES.PUNCH_LIST,
  TOOL_ENGINE_NAMES.RFIS,
  TOOL_ENGINE_NAMES.SUBMITTALS,
]

export const PAGINATION = {
  limit: 100,
  offset: 0
}

export const getExtractAutomationType = (automationType: string, enabled: boolean): string => {
  if (enabled) {
    if (automationType === 'automatic') {
      return 'On'
    } else if (automationType === 'manual') {
      return 'On';
    }
  } else {
    return 'Off';
  }
}

export const diffArrays = (arr1: Array<any>, arr2: Array<any>): number[] => {
  // Find the elements in arr1 that are not present in arr2
  return arr1.filter(num => !arr2.includes(num));
}

export const formatDate = (createdAt: Date, locale: string, timeZone?: string) => {
  const date = new Date(createdAt);
  return `${date.toLocaleDateString(locale, {
    timeZone: timeZone,
  })} at ${date.toLocaleTimeString(locale, {
    timeZone: timeZone,
  })}`;
};

export const calculateProgress = (logRequirments: LogRequirement[]): number => {
  if (logRequirments.length === 0) {
    return 0;
  } else {
    const completed = logRequirments.filter(logRequirement => logRequirement.status === 'complete').length;
    return Math.round((completed / logRequirments.length) * 100);
  }
}

//Returns URL path for given page name. Used for breadcrumb nav
export const getPathFromPage = (
  page: Page,
  accountId: string,
  projectId: number,
  logId: string
): string => {
  switch (page) {
    case 'PROJECT_HOME':
      return `/teams/${accountId}/projects/${projectId}/packages`;
    case 'CLOSEOUT_LOG':
      return `/teams/${accountId}/projects/${projectId}/closeout_logs/${logId}`;
    case 'EXTRACT_CLOSEOUT_LOG':
      return `/teams/${accountId}/projects/${projectId}/closeout_logs/${logId}/extract`;
    case 'NEW_INSTANT_EXTRACT':
      return `/teams/${accountId}/projects/${projectId}/packages/new`;
    default:
      return '';
  }
};

//Takes in path and state values and returns breadcrumbs with correct display names and links. Very tied to current route
export const getBreadcrumbsFromPath = (
  path: string,
  accountId: string,
  projectName: string,
  projectId: number,
  logId: string
): Breadcrumb[] => {
  if (!accountId) return [];

  const breadcrumbs: Array<Breadcrumb> = [];

  if (projectName && projectId) {
    //Add project breadcrumb
    breadcrumbs.push({
      displayName: projectName,
      path: getPathFromPage('PROJECT_HOME', accountId, projectId, logId),
      resetSelectedCloseoutLogId: true,
    });

    if (logId) {
      //Add closeout log breadcrumb
      breadcrumbs.push({
        displayName: 'Closeout Log',
        path: getPathFromPage('CLOSEOUT_LOG', accountId, projectId, logId),
        resetSelectedCloseoutLogId: false,
      });

      if (path.includes(`closeout_logs/${logId}/extract`)) {
        //Add extract options breadcrumb
        breadcrumbs.push({
          displayName: 'Extract Options',
          path: getPathFromPage(
            'EXTRACT_CLOSEOUT_LOG',
            accountId,
            projectId,
            logId
          ),
          resetSelectedCloseoutLogId: false,
        });
      }
    }

    if (path.includes('packages/new')) {
      //Add new instant extract breadcrumb
      breadcrumbs.push({
        displayName: 'New Extract',
        path: getPathFromPage(
          'NEW_INSTANT_EXTRACT',
          accountId,
          projectId,
          logId
        ),
        resetSelectedCloseoutLogId: false,
      });
    }
  }
  return breadcrumbs;
};

export const parseDate = (date?: any): Date | null => {
  if (date) {
    if (date && typeof date === 'string') {
      return parse(date, 'yyyy-MM-dd', new Date());
    } else if (date instanceof Date) {
      return date;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export const progressByResponsibleContractors = (logRequirments: LogRequirement[]): { id: number, contractor: string, progress: number }[] => {
  const responsibleContractors = logRequirments
    .map(logRequirement => logRequirement.responsibleContractor || { id: -1, name: 'Responsible Contractor Undefined' })
    .reduce((acc, nextItem) => {
      acc[nextItem.id] ||= nextItem.name

      return acc;
    }, {});

  const groupedRequirements = logRequirments.reduce((acc, logRequirement) => {
    const key = logRequirement.responsibleContractor || { id: -1, name: 'Responsible Contractor Undefined' };

    (acc[key.id] = acc[key.id] || []).push(logRequirement)

    return acc;
  }, {} as Record<number, LogRequirement[]>);

  return Object.keys(groupedRequirements).map(contractorId => {
    const requirements = groupedRequirements[contractorId]

    return {
      id: contractorId,
      contractor: responsibleContractors[contractorId],
      progress: calculateProgress(requirements)
    }
  }).sort((a, b) => (a.contractor || '').localeCompare(b.contractor || '', undefined, { numeric: true }));
}

export const selectedFiltersWithCreatedFromPackage = (state: NewPackageState, toolTabData: ToolTabData, filters: ProcoreToolFilter[], defaultFilters: ProcoreToolFilter[]): ProcoreToolFilter[] => {
  if (state.createdFromPackage) {
    const previousPackageQuery = state.createdFromPackage.packageQueries.find(packageQuery => {
      return packageQuery.procoreTool.id === toolTabData.procoreTool.id
    });

    const previousFilters = (previousPackageQuery?.filters || [])
    const filterKeys = previousFilters.map(f => f.key);

    const defaultSelectedFilters = defaultFilters.filter(f => !filterKeys.includes(f.key))

    const previousSelectedFilters = filters.reduce<ProcoreToolFilter[]>((acc, existingFilter) => {
      const overlapFilter = previousFilters.find(f => f.key === existingFilter.key);
      if (overlapFilter) {
        const filterValues = (overlapFilter.values || []).map(v => v.value);
        switch (overlapFilter.type) {
          case 'select':
            acc.push({
              ...existingFilter,
              values: (existingFilter.values || []).filter(filterValue => {
                return filterValues.some(f => {
                  return f.localeCompare(filterValue.value, undefined, { senstivity: 'base', numeric: 'true', usage: 'search' }) === 0
                })
              })
            });
            break;

          case 'daterange':
            acc.push({
              ...existingFilter,
              value: {
                startDate: overlapFilter.value?.start_date ? new Date(overlapFilter.value?.start_date) : undefined,
                endDate: overlapFilter.value?.end_date ? new Date(overlapFilter.value?.end_date) : undefined
              }
            })
            break;

          default:
            break;
        }
      }

      return acc;
    }, []);

    return [
      ...defaultSelectedFilters,
      ...previousSelectedFilters
    ]
  } else {
    return defaultFilters;
  }
}

export const booleanValueOrFallback = (value: any, fallback: boolean): boolean => {
  if (typeof value === 'boolean') {
    return value;
  } else {
    return fallback;
  }
}

export const checkedItems = (selectedItems: Record<string, SelectedItem>): SelectedItem[] => {
  return Object.values(selectedItems).filter(item => item.state === 'checked')
}

export const checkedItemIds = (selectedItems: Record<string, SelectedItem>): number[] => {
  return checkedItems(selectedItems).map(item => item.itemId)
}

export const disabledItemIds = (selectedItems: Record<string, SelectedItem>): number[] => {
  return Object.values(selectedItems).filter(item => item.state === 'disabled').map(item => item.itemId)
}

export const isWindowReadyForClose = (window: Window): boolean => {
  try {
    if (window.closed) {
      return true;
    }

    const pathname = window.location.pathname;

    if (pathname.includes("payment-confirmation")) {
      return false;
    } else {
      return pathname.includes("teams") || pathname.includes("close");
    }
  } catch {
    return false;
  }
};

export const groupingLabelToAttribute = (selectedGroupings: ProcoreToolGroupings[], engineName: string, attribute: string): Attribute => {
  const grouping = selectedGroupings.find(f => f.attribute === attribute);

  switch (engineName) {
    case TOOL_ENGINE_NAMES.PHOTOS: {
      switch (attribute) {
        case "uploaded_by_name": {
          return {
            label: "uploadedByName",
            defaultValue: "Uploaded By Undefined",
          };
        }

        case "month": {
          return {
            label: "month",
            defaultValue: "Month Undefined"
          }
        }

        case "week": {
          return {
            label: "week",
            defaultValue: "Week Undefined"
          }
        }

        case "location_name": {
          return {
            label: "locationName",
            defaultValue: "Location Undefined",
          };
        }

        case "image_category_name": {
          return {
            label: "imageCategoryName",
            defaultValue: "Album Undefined",
          };
        }
      }
      break;
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      switch (attribute) {
        case "plan_type_name": {
          return {
            label: "planTypeName",
            defaultValue: "Type Undefined"
          };
        }

        case "location_name": {
          return {
            label: "locationName",
            defaultValue: "Location Undefined"
          };
        }
      }
      break;
    }

    case TOOL_ENGINE_NAMES.FORMS: {
      switch (attribute) {
        case "form_template_name": {
          return {
            label: "formTemplateName",
            defaultValue: "Template Undefined"
          };
        }

        case "created_by_name": {
          return {
            label: "createdByName",
            defaultValue: "Created By Undefined"
          };
        }

        case "year_dash_month": {
          return {
            label: "yearDashMonth",
            defaultValue: "Year-Month Undefined"
          };
        }
      }
      break;
    }

    case TOOL_ENGINE_NAMES.SPECIFICATIONS: {
      switch (attribute) {
        case "specification_section_division_name": {
          return {
            label: "specificationSectionDivisionName",
            defaultValue: "Division Undefined"
          };
        }
      }
      break;
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      switch (attribute) {
        case "inspection_type": {
          return {
            label: "inspectionType",
            defaultValue: "Type Undefined",
          };
        }

        case "responsible_contractor": {
          return {
            label: "responsibleContractor",
            defaultValue: "Responsible Contractor Undefined",
          };
        }

        case "specification_section": {
          return {
            label: "specificationSection",
            defaultValue: "Specification Section Undefined",
          };
        }

        case "status": {
          return {
            label: "status",
            defaultValue: "Status Undefined",
          };
        }

        case "trade": {
          return {
            label: "trade",
            defaultValue: "Trade Undefined",
          };
        }

        case "list_template_name": {
          return {
            label: "listTemplateName",
            defaultValue: "Inspection Template Undefined",
          };
        }

        case "location_name": {
          return {
            label: "locationName",
            defaultValue: "Location Undefined",
          };
        }

        case "location_tier_1": {
          return {
            label: "locationTier1",
            defaultValue: "Location Tier 1 Undefined",
          };
        }

        case "identifier": {
          return {
            label: "formattedNumber",
            defaultValue: "Type Undefined",
          };
        }

        case "number": {
          return {
            label: "formattedNumber",
            defaultValue: "Type Undefined",
          };
        }
      }
      break;
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      switch (attribute) {
        case "location_name":
          return {
            label: "locationName",
            defaultValue: "Location Undefined",
          };
        case "trade_name":
          return {
            label: "tradeName",
            defaultValue: "Trade Undefined",
          };
        case "punch_item_type_name":
          return {
            label: "punchItemTypeName",
            defaultValue: "Type Undefined",
          };
        case "status":
          return {
            label: "status",
            defaultValue: "Status Undefined",
          };
        }
      break;
    }

    case TOOL_ENGINE_NAMES.DRAWINGS: {
      switch (attribute) {
        case "drawing_area_name":
          return {
            label: "drawingAreaName",
            defaultValue: "Drawing Area Undefined",
          };
        case "discipline_name":
          return {
            label: "disciplineName",
            defaultValue: "Discipline Undefined",
          };
        case "drawing_set_name":
          return {
            label: "drawingSetName",
            defaultValue: "Drawing Set Undefined",
          };
        }
      break;
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      switch (attribute) {
        case "type_name":
          return {
            label: "typeName",
            defaultValue: "Type Undefined",
          };

          case "location_name": {
            return {
              label: "locationName",
              defaultValue: "Location Undefined",
            };
          }

          case "status": {
            return {
              label: "status",
              defaultValue: "Status Undefined",
            };
          }

          case "trade": {
            return {
              label: "trade",
              defaultValue: "Trade Undefined",
            };
          }

          case "specification_section": {
            return {
              label: "specificationSection",
              defaultValue: "Spec Section Undefined",
            };
          }

          case "origin_type": {
            return {
              label: "originType",
              defaultValue: "Origin Type Undefined",
            };
          }

          case "assignee": {
            return {
              label: "assignee",
              defaultValue: "Assignee Undefined",
            };
          }

          case "created_by": {
            return {
              label: "createdBy",
              defaultValue: "Created By Undefined",
            };
          }
      }
      break;
    }

    case TOOL_ENGINE_NAMES.INCIDENTS: {
      switch (attribute) {
          case "location_name": {
            return {
              label: "locationName",
              defaultValue: "Location Undefined",
            };
          }

          case "status": {
            return {
              label: "status",
              defaultValue: "Status Undefined",
            };
          }
      }
      break;
    }

    case TOOL_ENGINE_NAMES.CORRESPONDENCE: {
      switch (attribute) {
          case "location_name": {
            return {
              label: "locationName",
              defaultValue: "Location Undefined",
            };
          }

          case "status": {
            return {
              label: "status",
              defaultValue: "Status Undefined",
            };
          }

          case "trade_name": {
            return {
              label: "tradeName",
              defaultValue: "Trade Undefined",
            };
          }

          case "generic_tool_item_type": {
            return {
              label: "genericToolItemType",
              defaultValue: "Type Undefined",
            };
          }
      }
      break;
    }

    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      switch (attribute) {
        case "submittal_type":
          return {
            label: "submittalType",
            defaultValue: grouping.defaultValue || "Type Undefined",
          };
        case "formatted_number":
          return {
            label: "formattedNumber",
            defaultValue: grouping.defaultValue || "Number Undefined",
          };
        case "submittal_package_title":
          return {
            label: "submittalPackageTitle",
            defaultValue: grouping.defaultValue || "Submittal Package Undefined",
          };
        case "spec_division_number":
          return {
            label: "specDivisionNumber",
            defaultValue: grouping.defaultValue || "Spec Division # Undefined",
          };
        case "spec_division":
          return {
            label: "specDivision",
            defaultValue: grouping.defaultValue || "Spec Division Undefined",
          };
      }
      break;
    }

    case TOOL_ENGINE_NAMES.MEETINGS: {
      switch (attribute) {
        case "meeting_template_name":
          return {
            label: "meetingTemplateName",
            defaultValue: grouping.defaultValue || "Template Undefined",
          };
      }
      break;
    }

    case TOOL_ENGINE_NAMES.CHANGE_EVENTS: {
      switch (attribute) {
        case "change_reason":
          return {
            label: "changeReason",
            defaultValue: grouping.defaultValue || "Change Reason Undefined",
          };

        case "change_type":
          return {
            label: "changeType",
            defaultValue: grouping.defaultValue || "Change Type Undefined",
          };

        case "number":
          return {
            label: "number",
            defaultValue: grouping.defaultValue || "Number Undefined",
          };
      }
      break;
    }

    case TOOL_ENGINE_NAMES.BIDDING: {
      switch (attribute) {
        case "vendor_name":
          return {
            label: "vendorName",
            defaultValue: grouping.defaultValue || "Company Undefined",
          };

        case "bid_package_title":
          return {
            label: "bidPackageTitle",
            defaultValue: grouping.defaultValue || "Bid Package Undefined",
          };
      }
      break;
    }

    case TOOL_ENGINE_NAMES.EQUIPMENT: {
      switch (attribute) {
        case "managed_equipment_type":
          return {
            label: "type",
            defaultValue: grouping.defaultValue || "Type Undefined",
          };

        case "managed_equipment_make":
          return {
            label: "make",
            defaultValue: grouping.defaultValue || "Make Undefined",
          };

        case "managed_equipment_model":
          return {
            label: "model",
            defaultValue: grouping.defaultValue || "Model Undefined",
          };
      }
      break;
    }

    default:
      break;
  }

  switch (attribute) {
    case "responsible_contractor":
      return {
        label: "responsibleContractor",
        defaultValue: grouping.defaultValue || "Responsible Contractor Undefined",
      };

    case "spec_section":
      return {
        label: "specSection",
        defaultValue: grouping.defaultValue || "Spec Section Undefined",
      };

    case "specification_section":
      return {
        label: "specificationSection",
        defaultValue: "Specification Section Undefined",
      };

    case "status":
      return {
        label: "status",
        defaultValue: "Status Undefined",
      };

    case "trade":
      return {
        label: "trade",
        defaultValue: "Trade Undefined",
      };

    case "trade_name":
      return {
        label: "tradeName",
        defaultValue: "Trade Undefined",
      };

    case "list_template_name":
      return {
        label: "listTemplateName",
        defaultValue: "Inspection Template Undefined",
      };

    case "number":
      return {
        label: "formattedNumber",
        defaultValue: "Inspection # Undefined",
      };

    case "identifier":
      return {
        label: "formattedNumber",
        defaultValue: "Inspection # Undefined",
      };

    case "location_name":
      return {
        label: "locationName",
        defaultValue: "Location Undefined",
      }

    case "project_stage_name":
      return {
        label: "projectStageName",
        defaultValue: "RFI Stage Undefined",
      }

    default:
      throw new Error();
  }
};

export const calculateParentCheckedState = (childrenState: CheckState[]): CheckState => {
  let newState: CheckState = 'unchecked';
  if (childrenState.every(state => state === 'checked')) {
    newState = 'checked';
  } else if (childrenState.some(state => state === 'checked' || state === 'indeterminate')) {
    newState = 'indeterminate';
  }

  return newState
}

export const arrayEquals = (a:any, b:any): boolean => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export const saveDataGridRowExpansionState = (closeoutId: number, logType: CloseoutTab | 'trade_portal' | 'create_file_requests', data: Record<string, boolean>): void => {
  try {
    if (logType === 'log') {
      // NOTE: We're not modifying the key because this has to backwards compatible with the old key.
      window.localStorage.setItem(`closeout-${closeoutId}-row-expansion-state`, JSON.stringify(data));
    } else {
      window.localStorage.setItem(`closeout-${closeoutId}-${logType}-row-expansion-state`, JSON.stringify(data));
    }
  } catch (e) {
    console.error(e);
  }
}

export const getDataGridRowExpansionState = (closeoutId: number, logType: CloseoutTab | 'trade_portal' | 'create_file_requests'): Record<string, boolean> => {
  try {
    const key = logType === 'log' ? `closeout-${closeoutId}-row-expansion-state` : `closeout-${closeoutId}-${logType}-row-expansion-state`;

    const result = window.localStorage.getItem(key);
    if (result) {
      return JSON.parse(result) as Record<string, boolean>;
    } else {
      return {};
    }
  } catch (e) {
    console.error(e);
    return {};
  }
}

export const sortProcoreItemsByFormattedTitle = (procoreItems: ProcoreItem[]): ProcoreItem[] => {
  return procoreItems.sort((a, b) => a.formattedTitle.localeCompare(b.formattedTitle, undefined, { numeric: true }));
}

export const sortByFormattedTitle = (recordAttachments: RecordAttachment[]): RecordAttachment[] => {
  return recordAttachments.sort((a, b) => a.formattedTitle.localeCompare(b.formattedTitle, undefined, { numeric: true }));
}

export const sortTitles = (titles: string[]): string[] => {
  return titles.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
}

export const getSelectAllOptionsWithFilter = (
  unmatchedSelectedOptions: SelectFilterValue[],
  filteredSelectedOptions: SelectFilterValue[],
  options: SelectFilterValue[]): SelectFilterValue[] => {
    if (unmatchedSelectedOptions.length === 0) {
      // All are selected, so return empty.
      if (filteredSelectedOptions.length > 0 && filteredSelectedOptions.length === options.length) {
        return [];
      // None or some filteredSelectedOptions are selected, so return all options.
      } else {
        return options;
      }
    } else {
      // All are selected, so return empty.
      if (filteredSelectedOptions.length > 0 && filteredSelectedOptions.length === options.length) {
        return [];
      // None or Some filteredSelectedOptions + selectedOptions are selected, so select all and include outside selections.
      } else {
        return (options.concat(unmatchedSelectedOptions));
      }
    }
  }

export const getCostPerMonth = (numberOfMonths: number): number => {
  switch (numberOfMonths) {
    case 1:
      return 250;
    case 2:
      return 200;
    case 3:
      return 150;
    case 4:
      return 125;
    case 5:
    case 6:
      return 100;
    case 7:
      return 600 / 7
    case 8:
      return 600 / 8;
    case 9:
      return 600 / 9;
    case 10:
      return 600 / 10;
    case 11:
      return 600 / 11;

    case 12:
      return 50;

    default:
      return 50;
  }
};

export const getTotalCost = (numberOfMonths: number): number => {
  const costPerMonth = getCostPerMonth(numberOfMonths);

  return costPerMonth * numberOfMonths;
};

export const getSelectAllOptions = (selectedOptions: SelectFilterValue[], options: SelectFilterValue[]): SelectFilterValue[] => {
  if (selectedOptions.length > 0 && selectedOptions.length === options.length) {
    return [];
  } else if (selectedOptions.length > 0 && selectedOptions.length < options.length) {
    return options;
  } else {
    return options;
  }
}

export const getDaysRemaining = (dateString: string): number => {
  const currentDate = new Date(dateString);

  const diff = Math.abs(currentDate.getTime() - new Date().getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
}

export const formatRole = (role: Role): string => {
  switch (role) {
    case 'none':
      return 'None';
    case 'admin':
      return 'Admin'
    case 'member':
      return 'Member'
    default:
      return '';
  }
}

export const oppositeRole = (role: Role): Role => {
  switch (role) {
    case 'none':
      return 'none';
    case 'admin':
      return 'member'
    case 'member':
      return 'admin'
    default:
      return 'none';
  }
}

export const formatUserName = (user: User): string => {
  if (user.vendorName) {
    return `${user.name} (${user.vendorName})`;
  } else {
    return `${user.name}`;
  }
}

export const isAnnualLicense = (account: Account): boolean => {
  return account.licenseType === 'annual';
}

export const isOwnerOrAdmin = (account: Account): boolean => {
  return account.personal && account.role === 'owner' ||
    account.role === 'admin';
}

export const isPilotOrCustomer = (account: Account): boolean => {
  return account.customerType === 'pilot' || account.customerType === 'customer';
}

export const isIframe = (window === window.parent || window.opener) ? false : true;

export const isEmpty = (name?: string | string[]): boolean => {
  return name === undefined || name === null || !name.length;
};

export const selectedItemKey = (engineName: string, procoreServerId: number, directoryType?: DirectoryType): string => {
  switch (engineName) {
    case TOOL_ENGINE_NAMES.RFIS: {
      return `rfi_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      return `submittal_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      return `inspection_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      return `observation_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.INCIDENTS: {
      return `incident_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.CORRESPONDENCE: {
      return `correspondence_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.PHOTOS: {
      return `photo_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      return `punch_item_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.DRAWINGS: {
      return `drawing_revision_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      return `action_plan_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.SPECIFICATIONS: {
      return `specification_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.FORMS: {
      return `form_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.EMAILS: {
      return `email_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.DIRECTORY: {
      if (!directoryType) {
        throw 'directoryType is required';
      } else {
        switch(directoryType) {
          case 'users':
            return `user_directory_${procoreServerId}`;
          case 'vendors':
            return `vendor_directory_${procoreServerId}`;
          default:
            throw 'unsupported directoryType';
        }
      }
    }

    case TOOL_ENGINE_NAMES.MEETINGS: {
      return `meeting_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.DAILY_LOG: {
      return `daily_log_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.CHANGE_EVENTS: {
      return `change_event_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.BIDDING: {
      return `bid_${procoreServerId}`;
    }

    case TOOL_ENGINE_NAMES.EQUIPMENT: {
      return `managed_equipment_${procoreServerId}`;
    }

    default:
      throw 'unsupported engine name';
  }
}

export const transformSelectedItemsFromSelectedItems = (engineName: string, selectedItems: Record<string, any>[], directoryType?: DirectoryType): Record<string, SelectedItem> => {
  return selectedItems.reduce((acc, selectedItem) => {
    if (engineName === TOOL_ENGINE_NAMES.DOCUMENTS) {
      acc[`${selectedItem.item_type}_${selectedItem.item_id}`] = {
        itemId: selectedItem.item_id,
        itemType: selectedItem.item_type,
        state: selectedItem.state
      } as SelectedItem;
    } else {
      acc[selectedItemKey(engineName, selectedItem.item_id, directoryType)] = {
        itemId: selectedItem.item_id,
        itemType: selectedItem.item_type,
        state: selectedItem.state
      } as SelectedItem;
    }

    return acc;
  }, {});
}

export const itemTypeFrom = (engineName: string, directoryType?: DirectoryType): string => {
  switch (engineName) {
    case TOOL_ENGINE_NAMES.RFIS: {
      return 'rfi';
    }

    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      return 'submittal';
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      return 'inspection';
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      return 'observation';
    }

    case TOOL_ENGINE_NAMES.INCIDENTS: {
      return 'incident';
    }

    case TOOL_ENGINE_NAMES.CORRESPONDENCE: {
      return 'correspondence';
    }

    case TOOL_ENGINE_NAMES.PHOTOS: {
      return 'photo';
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      return 'punch_item';
    }

    case TOOL_ENGINE_NAMES.DRAWINGS: {
      return 'drawing_revision';
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      return 'action_plan';
    }

    case TOOL_ENGINE_NAMES.SPECIFICATIONS: {
      return 'specification';
    }

    case TOOL_ENGINE_NAMES.FORMS: {
      return 'form';
    }

    case TOOL_ENGINE_NAMES.MEETINGS: {
      return 'meeting';
    }

    case TOOL_ENGINE_NAMES.CHANGE_EVENTS: {
      return 'change_event';
    }

    case TOOL_ENGINE_NAMES.EQUIPMENT: {
      return 'managed_equipment';
    }

    case TOOL_ENGINE_NAMES.BIDDING: {
      return 'bid';
    }

    case TOOL_ENGINE_NAMES.EMAILS: {
      return 'email';
    }

    case TOOL_ENGINE_NAMES.DIRECTORY: {
      if (!directoryType) {
        throw 'directoryType is required';
      } else {
        switch(directoryType) {
          case 'users':
            return 'user_directory';
          case 'vendors':
            return 'vendor_directory';
          default:
            throw 'unsupported directoryType';
        }
      }
    }

    case TOOL_ENGINE_NAMES.DAILY_LOG: {
      return 'daily_log';
    }

    default:
      throw `unsupported engine name: ${engineName}`;
  }
}

export const transformSelectedItemsFromServerIds = (engineName: string, procoreServerIds: number[], state: CheckState = 'checked', directoryType?: DirectoryType): Record<string, SelectedItem> => {
  switch (engineName) {
    case TOOL_ENGINE_NAMES.RFIS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'rfi',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'submittal',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'inspection',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'observation',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.INCIDENTS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'incident',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.CORRESPONDENCE: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'correspondence',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.PHOTOS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'photo',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'punch_item',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.DRAWINGS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'drawing_revision',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'action_plan',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.SPECIFICATIONS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'specification',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.FORMS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'form',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.MEETINGS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'meeting',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.CHANGE_EVENTS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'change_event',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.BIDDING: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'bid',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.EQUIPMENT: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[selectedItemKey(engineName, procoreServerId)] = {
          itemId: procoreServerId,
          itemType: 'managed_equipment',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.EMAILS: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[`email_${procoreServerId}`] = {
          itemId: procoreServerId,
          itemType: 'email',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.DIRECTORY: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        const itemType = itemTypeFrom(engineName, directoryType);
        acc[`${itemType}_${procoreServerId}`] = {
          itemId: procoreServerId,
          itemType: itemType,
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.DAILY_LOG: {
      return procoreServerIds.reduce((acc, procoreServerId) => {
        acc[`daily_log_${procoreServerId}`] = {
          itemId: procoreServerId,
          itemType: 'daily_log',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    default:
      return {};
  }
}

export const transformSelectedItems = (procoreTool: ProcoreTool, selectedItems: ProcoreItem[], state: CheckState = 'checked'): Record<string, SelectedItem> => {
  if (procoreTool === null || procoreTool === undefined) { return {}; }

  switch (procoreTool.engineName) {
    case TOOL_ENGINE_NAMES.RFIS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'rfi',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'submittal',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'inspection',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'observation',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.INCIDENTS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'incident',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.CORRESPONDENCE: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'correspondence',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.PHOTOS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'photo',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'punch_item',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.DRAWINGS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'drawing_revision',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'action_plan',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.SPECIFICATIONS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'specification',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.FORMS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'form',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.MEETINGS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'meeting',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.CHANGE_EVENTS: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'change_event',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.BIDDING: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'bid',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.EQUIPMENT: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'managed_equipment',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.DAILY_LOG: {
      return selectedItems.reduce((acc, item) => {
        acc[selectedItemKey(procoreTool.engineName, item.procoreServerId)] = {
          itemId: item.procoreServerId,
          itemType: 'daily_log',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.EMAILS: {
      return selectedItems.reduce((acc, item) => {
        acc[`email_${item.procoreServerId}`] = {
          itemId: item.procoreServerId,
          itemType: 'email',
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    case TOOL_ENGINE_NAMES.DIRECTORY: {
      return selectedItems.reduce((acc, item) => {
        const itemType = itemTypeFrom(procoreTool.engineName, procoreTool.directoryType);

        acc[`${itemType}_${item.procoreServerId}`] = {
          itemId: item.procoreServerId,
          itemType: itemType,
          state: state
        } as SelectedItem;

        return acc;
      }, {});
    }

    default:
      return {};
  }
}

export const procoreFilterToInputFilters = (filters: ProcoreToolFilter[]): FilterOptionInput[] => {
  return filters.filter(f => {
    if (isSelectFilter(f)) {
      return f.values.length > 0
    } else {
      const dateRangeFilterValue = f.value;
      return dateRangeFilterValue.endDate || dateRangeFilterValue.startDate;
    }
  }).map(f => {
    if (isSelectFilter(f)) {
      return {
        label: f.label,
        type: f.type,
        multiple: f.multiple,
        dataType: f.dataType,
        key: f.key,
        values: f.values.map(v => {
          return {
            id: v.id,
            key: v.key,
            value: v.value
          } as SelectFilterValueInput
        })
      } as FilterOptionInput;
    } else {
      const dateRangeFilterValue = f.value;
      return {
        label: f.label,
        type: f.type,
        multiple: f.multiple,
        dataType: f.dataType,
        key: f.key,
        values: [],
        value: {
          startDate: dateRangeFilterValue.startDate ? formatISO(dateRangeFilterValue.startDate) : null,
          endDate: dateRangeFilterValue.endDate ? formatISO(dateRangeFilterValue.endDate) : null
        } as DateRangeFilterValueInput
      } as FilterOptionInput;
    }
  })
};

export const isOneOfOperator: GridFilterOperator = {
  value: 'isOneOf',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }
    if (column.field === 'status') {
      return (params: GridCellParams): boolean => {
        return (
          filterItem.value.filter((val) => val.value === params.value).length >
          0
        );
      };
    } else {
      if (column.field.startsWith('custom_field_')) {
        return (params: GridCellParams): boolean => {
          return (
            filterItem.value.filter((val) => val.name === params.value['value']).length > 0
          );
        };
      } else {
        return (params: GridCellParams): boolean => {
          return (
            filterItem.value.filter((val) => val.name === params.value['name'])
              .length > 0
          );
        };
      }
    }
  },
};

export const procoreGroupingsToInputGroupings = (groupings: ProcoreToolGroupings[]): GroupingsInput[] => {
  return groupings.map(grouping => {
    return {
      id: grouping.id,
      label: grouping.label,
      attribute: grouping.attribute,
      attributeSource: grouping.attributeSource,
      defaultValue: grouping.defaultValue,
      groupingLabel: grouping.groupingLabel,
      operator: grouping.operator,
      conditional: grouping.conditional,
    } as GroupingsInput
  });
};

export function groupBy<T>(items: T[], getKey: (item: T) => string[]): Record<string, T[]> {
  return items.reduce((groups, item) => {
    const key = JSON.stringify(getKey(item));

    groups[key] = groups[key] || []
    groups[key].push(item);

    return groups;
  }, {});
}

export type Unauthorized = 401;
export type Forbidden = 403;
export type NotFound = 404;
export type StatusCode = Unauthorized | Forbidden | NotFound | undefined;

export function getStatusCodeFromApolloError(error: ApolloError): StatusCode {
  return error.graphQLErrors.map(error => error.extensions['code'] as StatusCode)[0];
}

export const logRequirementFrom = (props: { logReqType: NewLogRequirementType, logRequirementDescription: string, responsibleContractor?: ResponsibleContractor, specSection?: SpecSection }): LogRequirementCreateAttributes => {
  return {
    logRequirementTypeId: props.logReqType.id,
    logRequirementTypeName: props.logReqType.name,
    description: props.logRequirementDescription,
    responsibleContractorServerId: props.responsibleContractor?.procoreServerId,
    responsibleContractor: props.responsibleContractor?.name,
    specSectionServerId: props.specSection?.procoreServerId,
    specSection: props.specSection?.name,
    customFields: {}
  }
}

export const procoreToolHasOrganizeSupport = (procoreTool: ProcoreTool): boolean => {
  if (procoreTool === null) { return false; }

  switch (procoreTool.engineName) {
    case TOOL_ENGINE_NAMES.RFIS:
      return true;

    case TOOL_ENGINE_NAMES.DOCUMENTS:
      return false;

    case TOOL_ENGINE_NAMES.SUBMITTALS:
      return true;

    case TOOL_ENGINE_NAMES.INSPECTIONS:
      return true;

    case TOOL_ENGINE_NAMES.OBSERVATIONS:
      return true;

    case TOOL_ENGINE_NAMES.INCIDENTS:
      return true;

    case TOOL_ENGINE_NAMES.CORRESPONDENCE:
      return true;

    case TOOL_ENGINE_NAMES.PHOTOS:
      return true;

    case TOOL_ENGINE_NAMES.PUNCH_LIST:
      return true;

    case TOOL_ENGINE_NAMES.DRAWINGS:
      return true;

    case TOOL_ENGINE_NAMES.ACTION_PLANS:
      return true;

    case TOOL_ENGINE_NAMES.SPECIFICATIONS:
      return true;

    case TOOL_ENGINE_NAMES.FORMS:
      return true;

    case TOOL_ENGINE_NAMES.MEETINGS:
      return true;

    case TOOL_ENGINE_NAMES.CHANGE_EVENTS:
      return true;

    case TOOL_ENGINE_NAMES.BIDDING:
      return true;

    case TOOL_ENGINE_NAMES.EQUIPMENT:
      return true;

    case TOOL_ENGINE_NAMES.DAILY_LOG:
      return false;

    case TOOL_ENGINE_NAMES.EMAILS:
      return true;

    case TOOL_ENGINE_NAMES.DIRECTORY:
      return false;

    default:
      return false;
  }
}

export const getExtractOptionsForToolEngineName = (exportOptions: ExportOption[], engineName: string, previousOptions: Record<string, any> = {}, directoryType?: DirectoryType): ExtractOptions => {
  const exportOption = exportOptions.find(o => o.engineName === engineName) || { id: 1, engineName: engineName, options: {} };

  switch (engineName) {
    case TOOL_ENGINE_NAMES.DAILY_LOG: {
      return {
        show_created_by_on_log_entries: booleanValueOrFallback(previousOptions['show_created_by_on_log_entries'], true),
        image_attachment_option: previousOptions['image_attachment_option'] || exportOption.options['image_attachment_option'] || 'thumbnails_and_full_size',
      }
    }

    case TOOL_ENGINE_NAMES.RFIS: {
      return {
        response_option: previousOptions['response_option'] || 'official_only'
      };
    }

    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      return {
        attachment_option: previousOptions['attachment_option'] || 'distributed',
        single_pdf: booleanValueOrFallback(previousOptions['single_pdf'], true),
        workflow_responses: previousOptions['workflow_responses'] || [],
      };
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      return {
        single_pdf: booleanValueOrFallback(previousOptions['single_pdf'], true),
        attachment_option: previousOptions['attachment_option'] || 'all',
        include_linked_drawings: booleanValueOrFallback(previousOptions['include_linked_drawings'], booleanValueOrFallback(exportOption.options['include_linked_drawings'], true)),
        show_item_response_change_activity: booleanValueOrFallback(previousOptions['show_item_response_change_activity'], booleanValueOrFallback(exportOption.options['show_item_response_change_activity'], false)),
        show_only_latest_response_change_activity: booleanValueOrFallback(previousOptions['show_only_latest_response_change_activity'], booleanValueOrFallback(exportOption.options['show_only_latest_response_change_activity'], true)),
        include_linked_drawing_markup: booleanValueOrFallback(previousOptions['include_linked_drawing_markup'], booleanValueOrFallback(exportOption.options['include_linked_drawing_markup'], false)),
        include_other_markup: booleanValueOrFallback(previousOptions['include_other_markup'], booleanValueOrFallback(exportOption.options['include_other_markup'], false)),
        include_observations: booleanValueOrFallback(previousOptions['include_observations'], booleanValueOrFallback(exportOption.options['include_observations'], false)),
        show_not_applicable_items: booleanValueOrFallback(previousOptions['show_not_applicable_items'], booleanValueOrFallback(exportOption.options['show_not_applicable_items'], true)),
        collapse_not_applicable_sections: booleanValueOrFallback(previousOptions['collapse_not_applicable_sections'], booleanValueOrFallback(exportOption.options['collapse_not_applicable_sections'], false)),
        selected_attachment_options: {},
        image_attachment_option: previousOptions['image_attachment_option'] || exportOption.options['image_attachment_option'] || 'thumbnails_and_full_size',
        grouping_options: {},
      };
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      return {
        include_linked_drawing_markup: booleanValueOrFallback(previousOptions['include_linked_drawing_markup'], false),
        image_attachment_option: previousOptions['image_attachment_option'] || 'thumbnails_and_full_size'
      };
    }

    case TOOL_ENGINE_NAMES.PHOTOS: {
      return { };
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      return {
        image_attachment_option: previousOptions['image_attachment_option'] || 'thumbnails_and_full_size'
       };
    }

    case TOOL_ENGINE_NAMES.DRAWINGS: {
      return {
        include_correspondence: booleanValueOrFallback(previousOptions['include_correspondence'], false),
        include_rfis: booleanValueOrFallback(previousOptions['include_rfis'], false),
        include_documents: booleanValueOrFallback(previousOptions['include_documents'], false),
        include_inspections: booleanValueOrFallback(previousOptions['include_inspections'], false),
        include_observations: booleanValueOrFallback(previousOptions['include_observations'], false),
        include_photos: booleanValueOrFallback(previousOptions['include_photos'], false),
        include_punch_items: booleanValueOrFallback(previousOptions['include_punch_items'], false),
        include_sketches: booleanValueOrFallback(previousOptions['include_sketches'], false),
        include_submittals: booleanValueOrFallback(previousOptions['include_submittals'], false),
        include_sheet_links: booleanValueOrFallback(previousOptions['include_sheet_links'], false),
        include_other_markup: booleanValueOrFallback(previousOptions['include_other_markup'], false),
      };
    }

    case TOOL_ENGINE_NAMES.DOCUMENTS: {
      return {};
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      // TODO: Handle inspection and submittal options
      const previousInspectionOptions = (previousOptions['inspections'] || {})['options'] || {}
      const submittalOptions = (previousOptions['submittals'] || {})['options'] || {}

      const inspectionExportOptions = exportOptions.find(o => o.engineName === TOOL_ENGINE_NAMES.INSPECTIONS) || { id: 1, engineName: engineName, options: {} };

      return {
        inspections: {
          options: {
            single_pdf: booleanValueOrFallback(previousInspectionOptions['single_pdf'], true),
            show_item_response_change_activity: booleanValueOrFallback(previousInspectionOptions['show_item_response_change_activity'], booleanValueOrFallback(inspectionExportOptions.options['show_item_response_change_activity'], false)),
            show_only_latest_response_change_activity: booleanValueOrFallback(previousInspectionOptions['show_only_latest_response_change_activity'], booleanValueOrFallback(inspectionExportOptions.options['show_only_latest_response_change_activity'], true)),
            include_linked_drawings: booleanValueOrFallback(previousInspectionOptions['include_linked_drawings'], booleanValueOrFallback(inspectionExportOptions.options['include_linked_drawings'], true)),
            include_linked_drawing_markup: booleanValueOrFallback(previousInspectionOptions['include_linked_drawing_markup'], booleanValueOrFallback(inspectionExportOptions.options['include_linked_drawing_markup'], false)),
            include_other_markup: booleanValueOrFallback(previousInspectionOptions['include_other_markup'], booleanValueOrFallback(inspectionExportOptions.options['include_other_markup'], false)),
            include_observations: booleanValueOrFallback(previousInspectionOptions['include_observations'], booleanValueOrFallback(inspectionExportOptions.options['include_observations'], false)),
            image_attachment_option: previousInspectionOptions['image_attachment_option'] || inspectionExportOptions.options['image_attachment_option'] || 'thumbnails_and_full_size',
            show_not_applicable_items: booleanValueOrFallback(previousInspectionOptions['show_not_applicable_items'], booleanValueOrFallback(inspectionExportOptions.options['show_not_applicable_items'], true)),
            collapse_not_applicable_sections: booleanValueOrFallback(previousInspectionOptions['collapse_not_applicable_sections'], booleanValueOrFallback(inspectionExportOptions.options['collapse_not_applicable_sections'], false))
          }
        },
        submittals: {
          options: {
            single_pdf: booleanValueOrFallback(submittalOptions['single_pdf'], true),
            attachment_option: submittalOptions['attachment_option'] || 'distributed',
            workflow_responses: submittalOptions['workflow_responses'] || [],
          }
        },
        include_references: booleanValueOrFallback(previousOptions['include_references'], true),
        single_pdf: booleanValueOrFallback(previousOptions['single_pdf'], true),
      };
    }

    case TOOL_ENGINE_NAMES.SPECIFICATIONS: {
      return {};
    }

    case TOOL_ENGINE_NAMES.INCIDENTS: {
      return {};
    }

    case TOOL_ENGINE_NAMES.CORRESPONDENCE: {
      return {
        response_option: previousOptions['response_option'] || 'all'
      };
    }

    case TOOL_ENGINE_NAMES.FORMS: {
      return {};
    }

    case TOOL_ENGINE_NAMES.EMAILS: {
      return {};
    }

    case TOOL_ENGINE_NAMES.DIRECTORY: {
      if (!directoryType) {
        return {};
      }

      switch (directoryType) {
        case 'users': {
          return {
            include_job_title: booleanValueOrFallback(previousOptions['include_job_title'], true)
          };
        }
        case 'vendors': {
          return {};
        }
        default:
          return {};
      }
    }

    case TOOL_ENGINE_NAMES.MEETINGS: {
      return {
        single_pdf: true,
        image_attachment_option: previousOptions['image_attachment_option'] || exportOption.options['image_attachment_option'] || 'thumbnails_and_full_size',
      };
    }

    case TOOL_ENGINE_NAMES.CHANGE_EVENTS: {
      return {};
    }

    case TOOL_ENGINE_NAMES.BIDDING: {
      return {};
    }

    case TOOL_ENGINE_NAMES.EQUIPMENT: {
      return {
        image_attachment_option: previousOptions['image_attachment_option'] || 'thumbnails_and_full_size'
      };
    }

    default: {
      throw new Error('Unsupported tool title');
    }
  }
}

export const procoreToolHasOptionsSupport = (procoreTool: ProcoreTool): boolean => {
  if (procoreTool === null || procoreTool === undefined) { return false; }

  switch (procoreTool.engineName) {
    case TOOL_ENGINE_NAMES.RFIS:
      return true;

    case TOOL_ENGINE_NAMES.DOCUMENTS:
      return false;

    case TOOL_ENGINE_NAMES.SUBMITTALS:
      return true;

    case TOOL_ENGINE_NAMES.INSPECTIONS:
      return true;

    case TOOL_ENGINE_NAMES.OBSERVATIONS:
      return true;

    case TOOL_ENGINE_NAMES.INCIDENTS:
      return false;

    case TOOL_ENGINE_NAMES.CORRESPONDENCE:
      return true;

    case TOOL_ENGINE_NAMES.PHOTOS:
      return false;

    case TOOL_ENGINE_NAMES.PUNCH_LIST:
      return true;

    case TOOL_ENGINE_NAMES.DRAWINGS:
      return true;

    case TOOL_ENGINE_NAMES.ACTION_PLANS:
      return true;

    case TOOL_ENGINE_NAMES.SPECIFICATIONS:
      return false;

    case TOOL_ENGINE_NAMES.FORMS:
      return false;

    case TOOL_ENGINE_NAMES.EMAILS:
      return false;

    case TOOL_ENGINE_NAMES.DIRECTORY:
      return procoreTool.directoryType === 'users';

    case TOOL_ENGINE_NAMES.DAILY_LOG:
      return true;

    case TOOL_ENGINE_NAMES.MEETINGS:
      return false;

    case TOOL_ENGINE_NAMES.CHANGE_EVENTS:
      return false;

    case TOOL_ENGINE_NAMES.BIDDING:
      return false;

    case TOOL_ENGINE_NAMES.EQUIPMENT:
      return true;

    default:
      return false;
  }
}