import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { FilterOptionInput } from "../../types";
import { ProcorePhoto } from "../../types/photo";
import { ProcoreToolGroupings } from "../../types/submittal";

export type ProcorePhotoFilterData = {
  photoFilters: ProcoreToolFilter[];
  photoDefaultFilters: ProcoreToolFilter[];
  photoGroupings: ProcoreToolGroupings[];
};

export type ProcoreFilterPhotoData = {
  photos: ProcorePhoto[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcorePhotoVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterPhotoVariables = ProcorePhotoVariables & {
  filters: FilterOptionInput[];
  procoreToolId: number;
  searchValue?: string;
  limit: number;
  offset: number;
};

export const PhotoFragment = gql`
fragment PhotoFragment on ProcorePhoto {
  id
  procoreServerId
  formattedTitle
  imageCategoryName
  uploadedByName
  locationName
  filename
  description
  month
  week
}
`;

export const filterProcorePhotosQuery = gql`
query FilterProcorePhotos($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  photos(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...PhotoFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${PhotoFragment}
`;

export const fetchPaginatedProcorePhotosQuery = gql`
query FilterPaginatedProcorePhotos($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  photos(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...PhotoFragment
  }
}
${PhotoFragment}
`;