import { AutomationDestination, ExtractAutomationCloudStorageSystem } from '../types';
import { ProcoreToolGroupings } from '../types/submittal';
import { ExtractOptions, ProcoreToolFilter } from './NewPackageContext';


export interface EditAutomationState {
  accountId: string;
  id: number | null;
  name: string;
  sharedGroupings: ProcoreToolGroupings[];
  cloudStorageSystems: ExtractAutomationCloudStorageSystem[];
  toolAutomations: ToolAutomation[];
}

export interface ToolAutomation {
  id: number | null;
  procoreToolEngineName: string;
  title: string;
  filters: ProcoreToolFilter[];
  groupings: ProcoreToolGroupings[];
  selectedFilters: ProcoreToolFilter[];
  documentStorageSystem: string;
  cloudStorageSystem: ExtractAutomationCloudStorageSystem;
  destination: AutomationDestination;
  selectedGroupings: ProcoreToolGroupings[];
  extractOptions: ExtractOptions;
}

export const newAutomationInitialState: EditAutomationState = {
  accountId: '',
  id: null,
  name: 'Untitled Automation',
  cloudStorageSystems: [],
  sharedGroupings: [],
  toolAutomations: [],
};

export type Action =
  | { type: 'SET_AUTOMATION'; value: EditAutomationState }
  | { type: 'RESET_AUTOMATION' }
  | { type: 'SET_NAME'; value: { name: string } }
  | {
      type: 'SET_TOOL_DATA';
      value: {
        procoreToolEngineName: string;
        filters: ProcoreToolFilter[];
        groupings: ProcoreToolGroupings[];
      };
    }
  | { type: 'SET_TOOL_AUTOMATIONS'; value: {
    cloudStorageSystems: ExtractAutomationCloudStorageSystem[],
    toolAutomations: ToolAutomation[],
    sharedGroupings: ProcoreToolGroupings[]
    }
  }
  // TOD: Write unit test for this
  | { type: 'APPEND_TOOL_AUTOMATION'; value: ToolAutomation }
  | { type: 'REMOVE_TOOL_AUTOMATION'; value: { procoreToolEngineName: string } }
  | {
      type: 'APPEND_SELECTED_FILTER';
      value: { procoreToolEngineName: string; selectedFilter: ProcoreToolFilter };
    }
  | {
      type: 'UPDATE_SELECTED_FILTER';
      value: {
        procoreToolEngineName: string;
        selectedFilterKey: string;
        selectedFilter: ProcoreToolFilter;
      };
    }
  | {
      type: 'REMOVE_SELECTED_FILTER';
      value: { procoreToolEngineName: string; selectedFilterKey: string };
    }
  | {
      type: 'UPDATE_EXTRACT_OPTIONS';
      value: { procoreToolEngineName: string; extractOptions: ExtractOptions };
    }
  | {
      type: 'UPDATE_SELECTED_GROUPINGS';
      value: { procoreToolEngineName: string; selectedGroupings: ProcoreToolGroupings[] };
    }
    // TODO: Update me
  | { type: 'SET_DESTINATION'; value: {procoreToolEngineName: string, cloudStorageSystem: ExtractAutomationCloudStorageSystem, destination: AutomationDestination }};

  export const newAutomationReducer: React.Reducer<EditAutomationState, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case 'SET_AUTOMATION': {
      return {
        ...action.value,
      };
    }

    case 'RESET_AUTOMATION': {
      return newAutomationInitialState;
    }

    case 'SET_NAME': {
      return {
        ...state,
        name: action.value.name,
      };
    }

    case 'SET_TOOL_DATA': {
      return {
        ...state,
        toolAutomations: state.toolAutomations.map((toolAutomation) => {
          if (toolAutomation.procoreToolEngineName === action.value.procoreToolEngineName) {
            return {
              ...toolAutomation,
              filters: action.value.filters,
              groupings: action.value.groupings,
            };
          } else {
            return toolAutomation;
          }
        }),
      };
    }

    case 'UPDATE_EXTRACT_OPTIONS': {
      return {
        ...state,
        toolAutomations: state.toolAutomations.map((toolAutomation) => {
          if (toolAutomation.procoreToolEngineName === action.value.procoreToolEngineName) {
            return {
              ...toolAutomation,
              extractOptions: action.value.extractOptions,
            };
          } else {
            return toolAutomation;
          }
        }),
      };
    }

    case 'SET_TOOL_AUTOMATIONS': {
      return {
        ...state,
        cloudStorageSystems: action.value.cloudStorageSystems,
        toolAutomations: action.value.toolAutomations,
        sharedGroupings: action.value.sharedGroupings,
      };
    }

    case 'APPEND_TOOL_AUTOMATION': {
      return {
        ...state,
        toolAutomations: [...state.toolAutomations, action.value],
      };
    }

    case 'REMOVE_TOOL_AUTOMATION': {
      return {
        ...state,
        toolAutomations: state.toolAutomations.filter(
          (toolAutomation) =>
            toolAutomation.procoreToolEngineName !== action.value.procoreToolEngineName
        ),
      };
    }

    case 'APPEND_SELECTED_FILTER': {
      return {
        ...state,
        toolAutomations: state.toolAutomations.map((toolAutomation) => {
          if (toolAutomation.procoreToolEngineName === action.value.procoreToolEngineName) {
            return {
              ...toolAutomation,
              selectedFilters: [
                ...toolAutomation.selectedFilters,
                action.value.selectedFilter,
              ],
            };
          } else {
            return toolAutomation;
          }
        }),
      };
    }

    case 'UPDATE_SELECTED_FILTER': {
      return {
        ...state,
        toolAutomations: state.toolAutomations.map((toolAutomation) => {
          if (toolAutomation.procoreToolEngineName === action.value.procoreToolEngineName) {
            return {
              ...toolAutomation,
              selectedFilters: toolAutomation.selectedFilters.map(
                (selectedFilter) => {
                  if (selectedFilter.key === action.value.selectedFilterKey) {
                    return action.value.selectedFilter;
                  } else {
                    return selectedFilter;
                  }
                }
              ),
            };
          } else {
            return toolAutomation;
          }
        }),
      };
    }

    case 'REMOVE_SELECTED_FILTER': {
      return {
        ...state,
        toolAutomations: state.toolAutomations.map((toolAutomation) => {
          if (toolAutomation.procoreToolEngineName === action.value.procoreToolEngineName) {
            return {
              ...toolAutomation,
              selectedFilters: toolAutomation.selectedFilters.filter(
                (selectedFilter) =>
                  selectedFilter.key !== action.value.selectedFilterKey
              ),
            };
          } else {
            return toolAutomation;
          }
        }),
      };
    }

    case 'UPDATE_SELECTED_GROUPINGS': {
      return {
        ...state,
        toolAutomations: state.toolAutomations.map((toolAutomation) => {
          if (toolAutomation.procoreToolEngineName === action.value.procoreToolEngineName) {
            return {
              ...toolAutomation,
              selectedGroupings: action.value.selectedGroupings,
            };
          } else {
            return toolAutomation;
          }
        }),
      };
    }

    case 'SET_DESTINATION': {
      return {
        ...state,
        toolAutomations: state.toolAutomations.map((toolAutomation) => {
          if (toolAutomation.procoreToolEngineName === action.value.procoreToolEngineName) {
            return {
              ...toolAutomation,
              cloudStorageSystem: action.value.cloudStorageSystem,
              documentStorageSystem: action.value.cloudStorageSystem.documentStorageSystem,
              destination: action.value.destination,
            };
          } else {
            return toolAutomation;
          }
        }),
      }
    }
  }
};
export type NewAutomationStateContext = {
  state: EditAutomationState;
  dispatch: (action: Action) => void;
};
